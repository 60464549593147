import { render, staticRenderFns } from "./mQuesMultiRowText.vue?vue&type=template&id=c772f20c&scoped=true&"
import script from "./mQuesMultiRowText.vue?vue&type=script&lang=js&"
export * from "./mQuesMultiRowText.vue?vue&type=script&lang=js&"
import style0 from "./mQuesMultiRowText.vue?vue&type=style&index=0&id=c772f20c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c772f20c",
  null
  
)

export default component.exports