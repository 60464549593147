<template>
  <div class="form-preview">
    <div class="top" v-if="quesDataId">
      <div></div>
      <div class="equipment_switch">
						<div class="btn el-icon-monitor" :class="{'active': equipment == 1}" @click="equipment = 1"></div>
						<div class="btn el-icon-mobile" :class="{'active': equipment == 2}" @click="equipment = 2"></div>
      </div>
      <div class="close el-icon-close" @click="back"></div>
    </div>
    <div class="body">
      <div class="cant-answer" v-if="canAnswer == 0">
			<el-result icon="error" title="错误提示" :subTitle="cantMessage">
				<template slot="extra">
					<el-button type="primary" size="medium" @click="close">返回</el-button>
				</template>
			</el-result>
		</div>
		<template v-else>
			<div class="limit-page" v-if="startPassword == 1">
				<el-image>
					<div slot="error" class="image-slot">
						<i class="el-icon-lock"></i>
					</div>
				</el-image>
				<div class="pass-word">
					<el-input v-model="userInputPsd" type="password" placeholder="请输入答题密码"></el-input>
					<el-button type="primary" @click="confirmPsw">提交</el-button>
				</div>
			</div>
			<template v-else>
        <!-- PC端 -->
        <div class="preview-pc" v-if="equipment == 1">
          <previewPC  :logicFlag="logicFlag" :astrictControl="astrictControl"/>
        </div>
        <!-- 移动端 -->
        <div class="preview-mobile" :class="{view: this.identityType==''}" v-if="equipment == 2"> <!-- this.quesDataId=='' -->
          <div class="mobile-modle">
            <previewMobile  :logicFlag="logicFlag" :astrictControl="astrictControl"/>
          </div>
        </div>
			</template>
		</template>
    </div>
  </div>
</template>
<script>
import previewPC from "@/views/ques/previewPC";
import previewMobile from "@/views/ques/previewMobile";
import { get } from "/src/services/ajax_ucenter.js";
export default {
  components: {
    previewPC,
    previewMobile,
  },
  data () {
    return {
      identityType:'',
      formId: '',
			quesDataId: '',
			email: '',
			userId: '',
      canAnswer: 1,
			cantMessage: '',
			startPassword: 0,
			password: '',
			userInputPsd: '',
			id: '',
			formWidth: '',
			formHeight: '',
      logicFlag:true,
      equipment: 0,
      isMobile:0,
      astrictControl:{}
    }
  },
  created () {
      this.identityType = this.$route.query.identityType?this.$route.query.identityType:'';
      this.formId = this.$route.query.formId?this.$route.query.formId:sessionStorage.getItem('formId')?sessionStorage.getItem('formId'):'';
      this.quesDataId = this.$route.query.dataId?this.$route.query.dataId:'';
      this.email = this.$route.query.email?this.$route.query.email:'';
      this.userId = this.$route.query.userId?this.$route.query.userId:'';
			this.astrictControl = {
				quesId:this.formId,
				email:this.email,
				passWd:this.userInputPsd
			}
      if(!this.quesDataId){
        if(this.identityType){
          this.equipment = this.$route.query.pc?this.$route.query.pc:1;
        }else{
          this.loadFormSeting(this.formId);
        }
      }else{
        this.equipment = this.$route.query.pc?this.$route.query.pc:1;
      }
      
  },
  mounted () {
     
  },
  methods: {
    // 获取表单设置属性
		loadFormSeting(formId){
			const _this = this;
			get('/formadmin/astrict_control/astrict_control.jhtml',{formId}).then(res => {
				if(res.code == 200){
					if(res.data.astrictControl){
						let data = res.data.astrictControl;
						_this.startPassword = data.startPassword?data.startPassword:0;
						_this.password = data.password?data.password:'';
						if(data.setTime && data.setTime == 1 && (new Date(data.start_time).getTime() > new Date().getTime() || new Date(data.end_time).getTime() < new Date().getTime())){
							_this.canAnswer = 0;
							_this.cantMessage = '抱歉，不在答题时间内！';
							return false;
						}else if(data.emailInvite && data.emailInvite == 1 && _this.email == ''){
							_this.canAnswer = 0;
							_this.cantMessage = '抱歉，只能通过邮件打开收到的链接，参与答题！';
							return false;
						}else if(data.startPassword && data.startPassword == 1){
							return false;
						}else{
							this.getFormData();
						}
					}else{
						this.getFormData();
					}
				}

			}).catch(err => {
				_this.$message.error(err);
			})
		},
    // 获取模板表单数据
		getFormData(){
			const _this = this;
			get('/formadmin/quesmodel/ques_date_add.jhtml',_this.astrictControl).then(res => {
				if(res.code == 200){
					_this.isMobile = res.data.isMobile;
          if(this.isMobile && this.isMobile == 1){
            this.equipment = 2;
          }else{
            this.equipment = 1;
          }
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
    // 提交密码
		confirmPsw(){console.log(4444)
			if(this.userInputPsd == ''){
				this.$message.error('请输入密码！');
			}else{
				if(this.userInputPsd != this.password){
					this.$message.error('密码错误！');
					this.userInputPsd = '';
				}else{
					this.startPassword = 0;
					this.getFormData();
				}
			}
		},
		close(){
			window.location.href="about:blank";
			window.close();
		},
    back () {
      if(sessionStorage.getItem('referrer')){
				sessionStorage.removeItem('formId');
				window.location.href = sessionStorage.getItem('referrer') + '/formCloseBack.html'
			}else{
        this.$router.go(-1);
      }
    }
  },

};
</script>
<style lang="less" scoped>
.form-preview {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
.el-radio__label,  .el-checkbox__label{white-space: pre-wrap;}
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 30px 0;
    position: relative;
    overflow: hidden;

    .equipment_switch {
      display: flex;
      width: 90px;
      height: 34px;

      .btn {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid #ddd;
        background-color: #fff;
        font-size: 22px;
        color: #999;
        cursor: pointer;

        &:hover {
          color: var(--theme-color);
        }
      }

      .el-icon-monitor {
        border-right: none;
        border-radius: 3px 0 0 3px;
      }

      .el-icon-mobile {
        border-left: none;
        border-radius: 0 3px 3px 0;
      }

      .btn.active {
        background-color: var(--theme-color);
        border-color: var(--theme-color);
        color: #fff;
      }
    }

    .close {
      font-size: 30px;
      color: #999;
      cursor: pointer;
      transition: transform .2s;

      &:hover {
        transform: rotate(90deg);
        transition: transform .2s;
        color: var(--theme-color);
      }
    }
  }

  .body {
    flex: 1;
    overflow: hidden;

    .preview-pc{
      height: 100%;
      padding: 20px auto;
      box-sizing: border-box;
    }
    .preview-mobile {
      overflow-y: auto;
      height: 100%;
      .scan-preiview {
        padding: 100px 0 0 100px;
        text-align: center;

        .title {
          font-size: 16px;
        }

        .tips {
          margin: 10px 0 20px;
          font-size: 12px;
          color: var(--theme-orange-color);
        }

        .code_img {
          width: 180px;
          height: 180px;
          padding: 10px;
          margin: 0 auto;
          background-color: #fff;
          border-radius: 5px;
          overflow: hidden;

        }
      }
    }
    .preview-mobile.view{
      display: flex;
      justify-content: center;
      padding: 0 0 0 ;
      .mobile-modle {
        width: 447px;
        height: 863px;
        padding: 91px 36px 105px;
        background: url(../../assets/images/appear-mobile.png);
        overflow: hidden;
      }
    }
  }
  
		.limit-page{
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			height: 100%;
			padding: 0 0 20vh;
			.el-image{
				.el-icon-lock{
					font-size: 100px;
					color: #ccc;
				}
			}
			.pass-word{
				display: flex;
				align-items: center;
				justify-content: center;
				margin: 5vh 0 0;
				.el-input{
					margin: 0 10px 0 0;
				}
			}
		}
		.cant-answer{
			height: 100%;
			.el-result{
				height: 90%;
			}
		}
}</style>