<template>
	<div class="header">
		<div>
			<router-link v-if="!isJz" to="/">
				<img src="@/assets/logo.png" alt="瑞蚁云表单">
			</router-link>
		</div>
		<div v-if="isJz">
			<template v-if="showBtn">
				<el-button type="primary" size="medium" @click="saveForm(true)" :loading="isSaving">预览</el-button>
				<el-button type="primary" size="medium" @click="saveForm(false)" :loading="isSaving" :disabled="hasEdit == 0">保存</el-button>
			</template>
			<el-button size="medium" @click="closePage">关闭</el-button>
		</div>
		<div v-else>
			<div v-if="showBtn">
				<el-button type="primary" size="medium" @click="saveForm(true)" :loading="isSaving">预览</el-button>
				<el-button type="primary" size="medium" @click="saveForm(false)" :loading="isSaving" :disabled="hasEdit == 0">保存</el-button>
				<el-button size="medium" @click="back">关闭</el-button>
			</div>
		</div>
	</div>
</template>
<script>
export default{
	name: "designHeader",
	props: {
		isSaving: {
			type: Boolean,
		},
		showBtn: {
			type: Boolean,
		},
		hasEdit: {
			type: Number,
		},
		isJz: {
			type: Boolean,
		}
	},
	watch:{
		$route(to){
			// this.showBtn = to.path == '/' || to.path == '/questionBank' || to.path == '/ques_questionTypes' || to.path == '/ques_style'?true:false;
			if(to.query.jzHost){
				this.isJz = true;
				sessionStorage.setItem('isJz',true);
				sessionStorage.setItem('type_id',to.query.type_id);
				sessionStorage.setItem('referrer',to.query.jzHost);
			}
			if(to.query.fromType){
				sessionStorage.setItem('fromType',to.query.fromType);
			}
		}
	},
	methods: {
		saveForm(preview){
			this.$parent.saveForm(preview);
		},
		closePage(){
			if(sessionStorage.getItem('referrer')){
				sessionStorage.removeItem('formId');
				window.location.href = sessionStorage.getItem('referrer') + '/formCloseBack.html'
			}
		},
		back(){
			this.$router.push("/")
		}
	}
};
</script>
<style lang="less" scoped>
.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 60px;
	padding: 0 20px;
	border-bottom: 1px solid #eee;
	background-color: #fff;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	position: relative;
	z-index: 999;
}
</style>