<template>
	<div class="ybd-view">
		<designHeader :isSaving="isSaving" :showBtn="showBtn" :hasEdit="hasEdit" :isJz="isJz"></designHeader>
		<div class="container">
			<designLeftNav :fromType='fromType' :identityType='identityType'></designLeftNav>
			<div class="main">
				<div class="full-wrap">
					<div class="wrap question_types">
						<div class="tab_wrap" v-show="!headerImageListShowFlag">
							<div class="nav">
								<div class="item" :class="{active: tabNavCurrent === 0}" @click="tabNavCurrent = 0">控件</div>
								<span class="line"></span>
								<div class="item" :class="{active: tabNavCurrent === 1}" @click="tabNavCurrent = 1">大纲</div>
							</div>
							<div class="tab_list">
								<transition name="fade">
									<div class="tab_item widget" v-show="tabNavCurrent === 0">
										<div class="model_warp" v-for="widgetGroup in widgetGroupList" :key="widgetGroup._id">
											<div class="title">{{widgetGroup.name}}</div>
											<draggable v-model="widgetGroup.question" v-bind="{group:{name: 'itxst',pull:'clone'},sort: false,}" chosenClass="d-chosen" filter=".drag-tips,.drag-site" :move="dragMove" @end="dragEnd">
												<transition-group class="list widget-list" :class="'widget-list'+widgetGroup._id">
													<div class="item" v-for="item in widgetGroup.question" :key="item._id">
														<span class="txt">{{item.topicName}}</span>
													</div>
												</transition-group>
											</draggable>
										</div>
									</div>
								</transition>
								<transition name="fade">
									<div class="tab_item catalogue" v-show="tabNavCurrent === 1">
										<ul class="outline_list" v-if="list.length > 0">
											<li class="outline_item" v-for="(item,index) in list" :key="'cata' + index">
												<a href="javascript: void(0);">
													<i :class="'ico-'+item.modelType"></i>
													<span>{{index+1}}.{{item.topicName}}</span>
												</a>
											</li>
										</ul>
										<noData v-else text="暂时还没有表单控件哦！"></noData>
									</div>
								</transition>
							</div>
						</div>
						<div class="tab_wrap" v-show="headerImageListShowFlag">
							<!-- 页眉图片-->
							<transition name="widthZoom">
								<div class="header_image">
									<div class="main">
										<div class="head">
											<span class="label">主题</span>
											<i class="iconfont icon-shouqi" @click="headerImageListShowFlag = false"></i>
										</div>
										<div class="style_nav">
											<span class="label">风格</span>
											<ul class="list cl">
												<li :class="{active: headerImgTypeId == ''}" @click="headerImgTypeId = ''">全部</li>
												<li :class="{active: item._id == headerImgTypeId}" v-for="item in headerImgTypeList" :key="item._id" @click="headerImgTypeId = item._id">{{item.name}}</li>
											</ul>
										</div>
										<div class="img_list">
											<div class="item upload">
												<el-upload class="avatar-uploader" action="/com/uplaod_modelimg.do"  accept="image/*" :before-upload="beforeImgUpload" :on-success="handleHeaderImgSuccess" :show-file-list="false">
													<i class="el-icon-upload avatar-uploader-icon"></i>
													<span>上传图片</span>
												</el-upload>
											</div>
											<el-scrollbar v-if="headerImgList.length>0">
												<div class="item" :class="{'active': item.imgUrl == globalStyle.formHeader.imgSrc}" v-for="item in headerImgList" :key="item._id" @click="globalStyle.formHeader.imgSrc = item.imgUrl">
													<span class="bat">
														<i class="el-icon-check"></i>
													</span>
													<el-image class="img" :src="item.imgUrl" lazy></el-image>
												</div>
											</el-scrollbar>
											<noData v-else text="暂时还没有图片哦！"></noData>
										</div>
									</div>
								</div>
							</transition>
						</div>
						<div class="main_area">
							<div class="edit_cont" v-show="equipment == 1">
								<div class="edit_wrap">
									<div class="equipment_switch">
										<div class="btn el-icon-monitor" :class="{'active': equipment == 1}" @click="equipment = 1"></div>
										<div class="btn el-icon-mobile" :class="{'active': equipment == 2}" @click="equipment = 2"></div>
									</div>
									<div class="form_page">
										<div class="edit_inner" @click="setShow = true,currentWidget =''" :style="{width: globalStyle.formWidth + 'px'}">
											<div class="form-head" v-show="globalStyle.formHeader.isShow">
												<div v-show="globalStyle.formHeader.type === '文字'" id="formHeadText" class="txt" key="formHeadText">{{globalStyle.formHeader.textCont}}</div>
												<div v-show="globalStyle.formHeader.type === '图片'" class="img" key="formHeadImg">
													<img v-if="globalStyle.formHeader.imgSrc" :style="{height: globalStyle.formHeader.imgHeight + 'px'}" :src="globalStyle.formHeader.imgSrc">
												</div>
											</div>
											<div class="form-logo" v-show="globalStyle.formLogo.isShow" :style="{'text-align': globalStyle.formLogo.align=='左对齐'?'left':globalStyle.formLogo.align=='居中'?'center':'right'}">
												<img v-if="globalStyle.formLogo.imgSrc" :src="globalStyle.formLogo.imgSrc">
											</div>
											<div class="edit-head">
												<div id="formTitle" class="form-title" >
													<el-tooltip effect="dark" content="点击编辑问卷标题" placement="top-start" :disabled="formTitleTips">
														<el-input type="textarea" ref="titleText" :autosize="true" resize="none" placeholder=" 请输入问卷标题" v-model="globalStyle.formTitle.textCont" @focus="formTitleTips = true" @blur="formTitleTips = false"></el-input>
													</el-tooltip>
													<div class="ques_gross" v-if="scoreGross">总分<div>{{scoreGross}}</div></div>
												</div>
												<div id="formDescript" class="form-descript">
													<el-tooltip effect="dark" content="点击编辑问卷描述" placement="top-start" :disabled="formDescriptTips">
														<el-input type="textarea" ref="descriptText" :autosize="true" resize="none" placeholder=" 请输入问卷描述" v-model="globalStyle.formDescript.textCont" @focus="formDescriptTips = true" @blur="formDescriptTips = false"></el-input>
													</el-tooltip>
												</div>
											</div>
											<draggable v-model="list" class="parent-wrap" :class="{'is_dragging': isDragging}"  group="itxst" ghostClass="s-ghost" filter=".drag-tips,.drag-site" :move="childSortMove" @end="childSortEnd">
												<transition-group class="parent-list">
													<template v-if="list.length > 0">
														<div class="parent-item" v-for="(item,index) in list" :key="'p' + index">
															<div v-if="isDragging" class="drag-site"></div>
															<examTopicEdit v-if="item.editState" :widgetlist='widgetList' :detail="item" :index='item.sort' @close-add='closeAdd($event,index)' @confirm-add="confirmAdd($event,index)"/>
															<template v-else>
																<examWidget :item="item" :globalItemStyle='globalItemStyle' :list="list" :logicFlag="logicFlag"/>
																<div class="cover" v-show="!isDragging"></div>
																<div class="control_btn" v-show="!isDragging">
																	<ul v-if="item.modelType == 'hr'">
																		<li><a class="dele_ico" href="javascript: void(0);" @click="deleteWidget(index);" title="删除"></a></li>
																	</ul>
																	<ul v-else-if="item.modelType == 'text'">
																		<li><a class="edit_ico" href="javascript: void(0);" @click="topicUpdate(index);" title="编辑"></a></li>
																		<li><a class="dele_ico" href="javascript: void(0);" @click="deleteWidget(index);" title="删除"></a></li>
																	</ul>
																	<ul v-else>
																		<li><a class="edit_ico" href="javascript: void(0);" @click="topicUpdate(index);" title="编辑"></a></li>
																		<li v-if="list.length>1 && !formToken"><a class="logic_ico" href="javascript: void(0);" title="逻辑" @click="showLogic(index)"><i v-if="(item.logicJumps.length + item.showLogic.length)>0">{{item.logicJumps.length + item.showLogic.length}}</i></a></li>
																		<li><a class="copy_ico" href="javascript: void(0);" @click="copyWidget(index)" title="复制"></a></li>
																		<li><a class="dele_ico" href="javascript: void(0);" @click="deleteWidget(index);" title="删除"></a></li>
																	</ul>
																</div>
																<logicDialog v-if="item.modelType != 'hr' && item.modelType != 'text'" :detail="item" :list="list" :index='index' @close-logic='closeLogic($event,index)' @save-logic="saveLogic($event,index)"/>
															</template>
															<div v-if="isDragging" class="drag-site"></div>
														</div>
													</template>
													<template  v-else>
														<div v-if="dragTipsFlag" key="no-list" class="drag-tips">
															<div class="iconfont icon-yidong"></div>
															<div class="txt">拖拽控件至此处</div>
														</div>
													</template>
												</transition-group>
											</draggable>
											<div id="submitBtn" class="submit_btn" v-show="list.length > 0">
												<el-button type="primary">{{globalStyle.submitBtnStyle.text}}</el-button>
											</div>
										</div>
									</div>
								</div>
								<transition name="widthZoom">
									<div class="set_area" v-show="setShow">
										<div class="close_btn" @click="setShow = false">
											<i class="iconfont icon-shouqikuaijin"></i>
										</div>
										<div class="set_list">
											<!-- 主界面设置 -->
											<div class="set_item">
												<div class="title">外观设置</div>
												<div class="list">
													<div class="item">
														<div class="switch_btn">
															<div class="label">
																<span class="txt">主题颜色</span>
															</div>
															<el-color-picker v-model="globalStyle.themeColor" size="mini" show-alpha></el-color-picker>
														</div>
													</div>
													<div class="item">
														<div class="switch_btn">
															<div class="label">
																<span class="txt">表单宽度</span>
															</div>
															<div class="f_cont silder">
																<el-slider v-model="globalStyle.formWidth" :min="200" :max="1920" :step="10"></el-slider>
															</div>
														</div>
													</div>
													<div class="item">
														<div class="switch_btn">
															<div class="label">
																<span class="txt">页眉</span>
																<div class="explain">
																	<i class="el-icon-info"></i>
																	<div class="cont">
																		<i class="angle"></i>
																		<div class="tips-main">
																			<div class="imgbox">
																				<img src="@/assets/images/header_pc.png" alt="">
																			</div>
																			<div class="infobox">
																				<div class="tips-head">电脑页眉示例</div>
																				<div class="tips-txt">
																					<p class="p1">建议尺寸：</p>
																					<p class="p2">宽度750px，高度300px</p>
																					<p class="p1">格式要求：</p>
																					<p class="p2">JPEG、PNG、JPG和GIF</p>
																					<p class="p1">图片大小：</p>
																					<p class="p2">5M以下</p>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<el-switch v-model="globalStyle.formHeader.isShow"></el-switch>
														</div>
														<div class="sub_list" v-show="globalStyle.formHeader.isShow">
															<div class="sub_item">
																<div class="sub_label">展示类型</div>
																<div class="sub_cont">
																	<el-radio-group v-model="globalStyle.formHeader.type" size="mini">
																		<el-radio-button label="文字"></el-radio-button>
																		<el-radio-button label="图片"></el-radio-button>
																	</el-radio-group>
																</div>
															</div>
															<template v-if="globalStyle.formHeader.type === '文字'">
																<div class="sub_item">
																	<div class="sub_label">文字内容</div>
																	<div class="sub_cont">
																		<el-input :class="{'error': globalStyle.formHeader.isError}" v-model="globalStyle.formHeader.textCont" placeholder="请输入内容"></el-input>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">文字样式</div>
																	<div class="sub_cont text_style">
																		<el-select v-model="globalStyle.formHeader.textStyle.fontSize" placeholder="请选择">
																			<template v-for="i in 100">
																				<el-option v-if="i > 11" :label="i" :value="i" :key="i"></el-option>
																			</template>
																		</el-select>
																		<el-color-picker v-model="globalStyle.formHeader.textStyle.color" size="mini" show-alpha></el-color-picker>
																		<div class="iconfont icon-zitijiacu" :class="{active: globalStyle.formHeader.textStyle.fontWeight == 'bold'}" @click="globalStyle.formHeader.textStyle.fontWeight = globalStyle.formHeader.textStyle.fontWeight == 'bold'?'normal':'bold'"></div>
																		<div class="iconfont icon-T-qingxie" :class="{active: globalStyle.formHeader.textStyle.fontStyle == 'italic'}" @click="globalStyle.formHeader.textStyle.fontStyle = globalStyle.formHeader.textStyle.fontStyle == 'italic'?'normal':'italic'"></div>
																		<div class="iconfont icon-xiahuaxian" :class="{active: globalStyle.formHeader.textStyle.textDecoration == 'underline'}" @click="globalStyle.formHeader.textStyle.textDecoration = globalStyle.formHeader.textStyle.textDecoration == 'underline'?'none':'underline'"></div>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">对齐方式</div>
																	<div class="sub_cont">
																		<el-radio-group v-model="globalStyle.formHeader.textStyle.textAlign" size="mini">
																			<el-radio-button label="居左"></el-radio-button>
																			<el-radio-button label="居中"></el-radio-button>
																			<el-radio-button label="居右"></el-radio-button>
																		</el-radio-group>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">背景颜色</div>
																	<div class="sub_cont">
																		<el-color-picker v-model="globalStyle.formHeader.textStyle.backgroundColor" size="mini" show-alpha></el-color-picker>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">内边距</div>
																	<div class="sub_cont">
																		<el-radio-group v-model="formHeadTextPaddingFlag" size="mini">
																			<el-radio-button label="上下"></el-radio-button>
																			<el-radio-button label="左右"></el-radio-button>
																		</el-radio-group>
																	</div>
																</div>
																<div class="sub_item" v-show="formHeadTextPaddingFlag == '上下'">
																	<div class="sub_label">上下</div>
																	<div class="sub_cont silder">
																		<el-slider v-model="globalStyle.formHeader.textStyle.paddingVertical" :max="100"></el-slider>
																	</div>
																</div>
																<div class="sub_item" v-show="formHeadTextPaddingFlag == '左右'">
																	<div class="sub_label">左右</div>
																	<div class="sub_cont silder">
																		<el-slider v-model="globalStyle.formHeader.textStyle.paddingHorizontal" :max="100"></el-slider>
																	</div>
																</div>
															</template>
															<template v-else>
																<div class="sub_item">
																	<div class="sub_label">选择图片</div>
																	<div class="sub_cont">
																		<div class="avatar-uploader">
																				<div class="el-upload" @click="headerImageListShowFlag = true">
																					<div v-if="globalStyle.formHeader.imgSrc" class="avatar">
																						<img :src="globalStyle.formHeader.imgSrc">
																						<div class="el-icon-error" @click.stop="globalStyle.formHeader.imgSrc = ''"></div>
																					</div>
																					<i v-else class="el-icon-plus avatar-uploader-icon"></i>
																				</div>
																			</div>
																		<!-- <el-upload class="avatar-uploader el-uploa" action="/com/uplaod_modelimg.do"  accept="image/*" :before-upload="beforeImgUpload" :on-success="handleHeaderImgSuccess" :show-file-list="false">
																			<div v-if="globalStyle.formHeader.imgSrc" class="avatar">
																					<img :src="globalStyle.formHeader.imgSrc">
																					<div class="el-icon-error" @click.stop="globalStyle.formHeader.imgSrc = ''"></div>
																				</div>
																				<i v-else class="el-icon-plus avatar-uploader-icon"></i>
																		</el-upload> -->
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">图片高度</div>
																	<div class="sub_cont silder">
																		<el-slider v-model="globalStyle.formHeader.imgHeight" :min="40" :max="500"></el-slider>
																	</div>
																</div>
															</template>
														</div>
													</div>
													<div class="item">
														<div class="switch_btn">
															<div class="label">
																<span class="txt">背景</span>
																<div class="explain">
																	<i class="el-icon-info"></i>
																	<div class="cont">
																		<i class="angle"></i>
																		<div class="tips-main">
																			<div class="imgbox">
																				<img src="@/assets/images/background_pc.png" alt="">
																			</div>
																			<div class="infobox">
																				<div class="tips-head">电脑背景示例</div>
																				<div class="tips-txt">
																					<p class="p1">建议尺寸：</p>
																					<p class="p2">宽度1920px，高度1080px</p>
																					<p class="p1">格式要求：</p>
																					<p class="p2">JPEG、PNG、JPG和GIF</p>
																					<p class="p1">图片大小：</p>
																					<p class="p2">5M以下</p>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<el-switch v-model="globalStyle.formBg.isShow"></el-switch>
														</div>
														<div class="sub_list" v-show="globalStyle.formBg.isShow">
															<div class="sub_item">
																<div class="sub_label">背景类型</div>
																<div class="sub_cont">
																	<el-radio-group v-model="globalStyle.formBg.type" size="mini">
																		<el-radio-button label="颜色"></el-radio-button>
																		<el-radio-button label="图片"></el-radio-button>
																		<el-radio-button label="隐藏"></el-radio-button>
																	</el-radio-group>
																</div>
															</div>
															<div class="sub_item" v-show="globalStyle.formBg.type == '颜色'">
																<div class="sub_label">颜色</div>
																<div class="sub_cont">
																	<el-color-picker v-model="globalStyle.formBg.color" size="mini" show-alpha></el-color-picker>
																</div>
															</div>
															<div class="sub_item" v-show="globalStyle.formBg.type == '图片'">
																<div class="sub_label">图片</div>
																<div class="sub_cont">
																	<el-upload class="avatar-uploader" action="/com/uplaod_modelimg.do"  accept="image/*" :before-upload="beforeImgUpload" :on-success="handleFormBgSuccess" :show-file-list="false">
																		<div v-if="globalStyle.formBg.imgSrc" class="avatar">
																			<img :src="globalStyle.formBg.imgSrc">
																			<div class="el-icon-error" @click.stop="globalStyle.formBg.imgSrc = ''"></div>
																		</div>
																		<i v-else class="el-icon-plus avatar-uploader-icon"></i>
																	</el-upload>
																</div>
															</div>
															<div class="sub_item" v-show="globalStyle.formBg.type == '图片'">
																<div class="sub_label">背景位置</div>
																<div class="sub_cont">
																	<el-radio-group v-model="globalStyle.formBg.position" size="mini">
																		<el-radio-button label="平铺"></el-radio-button>
																		<el-radio-button label="居中"></el-radio-button>
																	</el-radio-group>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">表单背景设置</div>
																<div class="sub_cont">
																	<el-radio-group v-model="globalStyle.formBg.contentBgType" size="mini">
																		<el-radio-button label="颜色"></el-radio-button>
																		<el-radio-button label="隐藏"></el-radio-button>
																	</el-radio-group>
																</div>
															</div>
															<div class="sub_item" v-show="globalStyle.formBg.contentBgType == '颜色'">
																<div class="sub_label">颜色</div>
																<div class="sub_cont">
																	<el-color-picker v-model="globalStyle.formBg.contentBgColor" size="mini" show-alpha></el-color-picker>
																</div>
															</div>
														</div>
													</div>
													<div class="item">
														<div class="switch_btn">
															<div class="label">
																<span class="txt">logo</span>
																<div class="explain">
																	<i class="el-icon-info"></i>
																	<div class="cont">
																		<i class="angle"></i>
																		<div class="tips-main">
																			<div class="imgbox">
																				<img src="@/assets/images/background_pc.png" alt="">
																			</div>
																			<div class="infobox">
																				<div class="tips-head">电脑logo示例</div>
																				<div class="tips-txt">
																					<p class="p1">建议尺寸：</p>
																					<p class="p2">宽度100px，高度50px</p>
																					<p class="p1">格式要求：</p>
																					<p class="p2">JPEG、PNG、JPG和GIF</p>
																					<p class="p1">图片大小：</p>
																					<p class="p2">5M以下</p>
																				</div>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<el-switch v-model="globalStyle.formLogo.isShow"></el-switch>
														</div>
														<div class="sub_list" v-show="globalStyle.formLogo.isShow">
															<div class="sub_item">
																<div class="sub_label">图片</div>
																<div class="sub_cont">
																	<el-upload class="avatar-uploader" action="/com/uplaod_modelimg.do"  accept="image/*" :before-upload="beforeImgUpload" :on-success="handleFormLogoSuccess" :show-file-list="false">
																		<div v-if="globalStyle.formLogo.imgSrc" class="avatar">
																			<img :src="globalStyle.formLogo.imgSrc">
																			<div class="el-icon-error" @click.stop="globalStyle.formLogo.imgSrc = ''"></div>
																		</div>
																		<i v-else class="el-icon-plus avatar-uploader-icon"></i>
																	</el-upload>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">位置</div>
																<div class="sub_cont">
																	<el-radio-group v-model="globalStyle.formLogo.align" size="mini">
																		<el-radio-button label="左对齐"></el-radio-button>
																		<el-radio-button label="居中"></el-radio-button>
																		<el-radio-button label="右对齐"></el-radio-button>
																	</el-radio-group>
																</div>
															</div>
														</div>
													</div>
													<div class="item">
														<div class="switch_btn">
															<div class="label">
																<span class="txt">表单标题</span>
															</div>
															<el-switch v-model="globalStyle.formTitle.isShow"></el-switch>
														</div>
														<div class="sub_list" v-show="globalStyle.formTitle.isShow">
															<div class="sub_item">
																<div class="sub_label">文字内容</div>
																<div class="sub_cont">
																	<el-input :class="{'error': globalStyle.formTitle.isError}" v-model="globalStyle.formTitle.textCont" placeholder="请输入表单标题"></el-input>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">文字样式</div>
																<div class="sub_cont text_style">
																	<el-select v-model="globalStyle.formTitle.textStyle.fontSize" placeholder="请选择">
																		<template v-for="i in 100">
																			<el-option v-if="i > 11" :label="i" :value="i" :key="i"></el-option>
																		</template>
																	</el-select>
																	<el-color-picker v-model="globalStyle.formTitle.textStyle.color" size="mini" show-alpha></el-color-picker>
																	<div class="iconfont icon-zitijiacu" :class="{active: globalStyle.formTitle.textStyle.fontWeight == 'bold'}" @click="globalStyle.formTitle.textStyle.fontWeight = globalStyle.formTitle.textStyle.fontWeight == 'bold'?'normal':'bold'"></div>
																	<div class="iconfont icon-T-qingxie" :class="{active: globalStyle.formTitle.textStyle.fontStyle == 'italic'}" @click="globalStyle.formTitle.textStyle.fontStyle = globalStyle.formTitle.textStyle.fontStyle == 'italic'?'normal':'italic'"></div>
																	<div class="iconfont icon-xiahuaxian" :class="{active: globalStyle.formTitle.textStyle.textDecoration == 'underline'}" @click="globalStyle.formTitle.textStyle.textDecoration = globalStyle.formTitle.textStyle.textDecoration == 'underline'?'none':'underline'"></div>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">内边距</div>
																<div class="sub_cont">
																	<el-radio-group v-model="formTitlePaddingFlag" size="mini">
																		<el-radio-button label="上"></el-radio-button>
																		<el-radio-button label="左右"></el-radio-button>
																		<el-radio-button label="下"></el-radio-button>
																	</el-radio-group>
																</div>
															</div>
															<div class="sub_item" v-show="formTitlePaddingFlag == '上'">
																<div class="sub_label">上</div>
																<div class="sub_cont silder">
																	<el-slider v-model="globalStyle.formTitle.textStyle.paddingTop" :max="100"></el-slider>
																</div>
															</div>
															<div class="sub_item" v-show="formTitlePaddingFlag == '左右'">
																<div class="sub_label">左右</div>
																<div class="sub_cont silder">
																	<el-slider v-model="globalStyle.formTitle.textStyle.paddingHorizontal" :max="100"></el-slider>
																</div>
															</div>
															<div class="sub_item" v-show="formTitlePaddingFlag == '下'">
																<div class="sub_label">下</div>
																<div class="sub_cont silder">
																	<el-slider v-model="globalStyle.formTitle.textStyle.paddingBottom" :max="100"></el-slider>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">背景设置</div>
																<div class="sub_cont">
																	<el-radio-group v-model="globalStyle.formTitle.textStyle.bgType" size="mini">
																		<el-radio-button label="默认"></el-radio-button>
																		<el-radio-button label="颜色"></el-radio-button>
																		<el-radio-button label="图片"></el-radio-button>
																	</el-radio-group>
																</div>
															</div>
															<div class="sub_item" v-show="globalStyle.formTitle.textStyle.bgType == '颜色'">
																<div class="sub_label">选择颜色</div>
																<div class="sub_cont">
																	<el-color-picker v-model="globalStyle.formTitle.textStyle.backgroundColor" size="mini" show-alpha></el-color-picker>
																</div>
															</div>
															<div class="sub_item" v-show="globalStyle.formTitle.textStyle.bgType == '图片'">
																<div class="sub_label">上传图片</div>
																<div class="sub_cont">
																	<el-upload class="avatar-uploader" action="/com/uplaod_modelimg.do"  accept="image/*" :before-upload="beforeImgUpload" :on-success="handleFormTitleSuccess" :show-file-list="false">
																		<div v-if="globalStyle.formTitle.textStyle.backgroundImage" class="avatar">
																			<img :src="globalStyle.formTitle.textStyle.backgroundImage">
																			<div class="el-icon-error" @click.stop="globalStyle.formTitle.textStyle.backgroundImage = ''"></div>
																		</div>
																		<i v-else class="el-icon-plus avatar-uploader-icon"></i>
																	</el-upload>
																</div>
															</div>
														</div>
													</div>
													<div class="item">
														<div class="switch_btn">
															<div class="label">
																<span class="txt">表单描述</span>
															</div>
															<el-switch v-model="globalStyle.formDescript.isShow"></el-switch>
														</div>
														<div class="sub_list" v-show="globalStyle.formDescript.isShow">
															<div class="sub_item">
																<div class="sub_label">文字内容</div>
																<div class="sub_cont">
																	<el-input :class="{'error': globalStyle.formDescript.isError}" v-model="globalStyle.formDescript.textCont" type="textarea" :rows="3" resize="none" placeholder="请输入表单描述"></el-input>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">文字样式</div>
																<div class="sub_cont text_style">
																	<el-select v-model="globalStyle.formDescript.textStyle.fontSize" placeholder="请选择">
																		<template v-for="i in 100">
																			<el-option v-if="i > 11" :label="i" :value="i" :key="i"></el-option>
																		</template>
																	</el-select>
																	<el-color-picker v-model="globalStyle.formDescript.textStyle.color" size="mini" show-alpha></el-color-picker>
																	<div class="iconfont icon-zitijiacu" :class="{active: globalStyle.formDescript.textStyle.fontWeight == 'bold'}" @click="globalStyle.formDescript.textStyle.fontWeight = globalStyle.formDescript.textStyle.fontWeight == 'bold'?'normal':'bold'"></div>
																	<div class="iconfont icon-T-qingxie" :class="{active: globalStyle.formDescript.textStyle.fontStyle == 'italic'}" @click="globalStyle.formDescript.textStyle.fontStyle = globalStyle.formDescript.textStyle.fontStyle == 'italic'?'normal':'italic'"></div>
																	<div class="iconfont icon-xiahuaxian" :class="{active: globalStyle.formDescript.textStyle.textDecoration == 'underline'}" @click="globalStyle.formDescript.textStyle.textDecoration = globalStyle.formDescript.textStyle.textDecoration == 'underline'?'none':'underline'"></div>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">内边距</div>
																<div class="sub_cont">
																	<el-radio-group v-model="formDescriptPaddingFlag" size="mini">
																		<el-radio-button label="上下"></el-radio-button>
																		<el-radio-button label="左右"></el-radio-button>
																	</el-radio-group>
																</div>
															</div>
															<div class="sub_item" v-show="formDescriptPaddingFlag == '上下'">
																<div class="sub_label">上下</div>
																<div class="sub_cont silder">
																	<el-slider v-model="globalStyle.formDescript.textStyle.paddingVertical" :max="100"></el-slider>
																</div>
															</div>
															<div class="sub_item" v-show="formDescriptPaddingFlag == '左右'">
																<div class="sub_label">左右</div>
																<div class="sub_cont silder">
																	<el-slider v-model="globalStyle.formDescript.textStyle.paddingHorizontal" :max="100"></el-slider>
																</div>
															</div>
														</div>
													</div>
													<div class="item">
														<div class="switch_btn">
															<div class="label">
																<span class="txt">表单项题干样式</span>
															</div>
															<div class="fold_btn" :class="{active: labelFoldFlag}" @click="labelFoldFlag = !labelFoldFlag">
																<span>{{labelFoldFlag?'收起':'展开'}}</span>
																<i class="iconfont icon-zhankai"></i>
															</div>
														</div>
														<transition name="fade">
															<div class="sub_list" v-show="labelFoldFlag">
																<div class="sub_item">
																	<div class="sub_label">文字样式</div>
																	<div class="sub_cont text_style">
																		<el-select v-model="globalItemStyle.labelStyle.fontSize" placeholder="请选择">
																			<template v-for="i in 100">
																				<el-option v-if="i > 11" :label="i" :value="i" :key="i"></el-option>
																			</template>
																		</el-select>
																		<el-color-picker v-model="globalItemStyle.labelStyle.color" size="mini" show-alpha></el-color-picker>
																		<div class="iconfont icon-zitijiacu" :class="{active: globalItemStyle.labelStyle.fontWeight == 'bold'}" @click="globalItemStyle.labelStyle.fontWeight = globalItemStyle.labelStyle.fontWeight == 'bold'?'normal':'bold'"></div>
																		<div class="iconfont icon-T-qingxie" :class="{active: globalItemStyle.labelStyle.fontStyle == 'italic'}" @click="globalItemStyle.labelStyle.fontStyle = globalItemStyle.labelStyle.fontStyle == 'italic'?'normal':'italic'"></div>
																		<div class="iconfont icon-xiahuaxian" :class="{active: globalItemStyle.labelStyle.textDecoration == 'underline'}" @click="globalItemStyle.labelStyle.textDecoration = globalItemStyle.labelStyle.textDecoration == 'underline'?'none':'underline'"></div>
																	</div>
																</div>
															</div>
														</transition>
													</div>
													<div class="item">
														<div class="switch_btn">
															<div class="label">
																<span class="txt">表单选项样式</span>
															</div>
															<div class="fold_btn" :class="{active: itemFoldFlag}" @click="itemFoldFlag = !itemFoldFlag">
																<span>{{itemFoldFlag?'收起':'展开'}}</span>
																<i class="iconfont icon-zhankai"></i>
															</div>
														</div>
														<transition name="fade">
															<div class="sub_list" v-show="itemFoldFlag">
																<div class="sub_item">
																	<div class="sub_label">文字样式</div>
																	<div class="sub_cont text_style">
																		<el-select v-model="globalItemStyle.itemStyle.fontSize" placeholder="请选择">
																			<template v-for="i in 100">
																				<el-option v-if="i > 11" :label="i" :value="i" :key="i"></el-option>
																			</template>
																		</el-select>
																		<el-color-picker v-model="globalItemStyle.itemStyle.color" size="mini" show-alpha></el-color-picker>
																		<div class="iconfont icon-zitijiacu" :class="{active: globalItemStyle.itemStyle.fontWeight == 'bold'}" @click="globalItemStyle.itemStyle.fontWeight = globalItemStyle.itemStyle.fontWeight == 'bold'?'normal':'bold'"></div>
																		<div class="iconfont icon-T-qingxie" :class="{active: globalItemStyle.itemStyle.fontStyle == 'italic'}" @click="globalItemStyle.itemStyle.fontStyle = globalItemStyle.itemStyle.fontStyle == 'italic'?'normal':'italic'"></div>
																		<div class="iconfont icon-xiahuaxian" :class="{active: globalItemStyle.itemStyle.textDecoration == 'underline'}" @click="globalItemStyle.itemStyle.textDecoration = globalItemStyle.itemStyle.textDecoration == 'underline'?'none':'underline'"></div>
																	</div>
																</div>
															</div>
														</transition>
													</div>
													<div class="item">
														<div class="switch_btn">
															<div class="label">
																<span class="txt">提交按钮</span>
															</div>
															<div class="fold_btn" :class="{active: submitBtnFoldFlag}" @click="submitBtnFoldFlag = !submitBtnFoldFlag">
																<span>{{submitBtnFoldFlag?'收起':'展开'}}</span>
																<i class="iconfont icon-zhankai"></i>
															</div>
														</div>
														<transition name="fade">
															<div class="sub_list" v-show="submitBtnFoldFlag">
																<div class="sub_item">
																	<div class="sub_label">按钮文字</div>
																	<div class="sub_cont">
																		<el-input :class="{'error': globalStyle.submitBtnStyle.isError}" v-model="globalStyle.submitBtnStyle.text" maxlength="10" placeholder="请输入按钮文字"></el-input>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">按钮宽度</div>
																	<div class="sub_cont silder">
																		<el-slider v-model="globalStyle.submitBtnStyle.width" :max="500" :min="40"></el-slider>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">按钮高度</div>
																	<div class="sub_cont silder">
																		<el-slider v-model="globalStyle.submitBtnStyle.height" :min="20"></el-slider>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">按钮位置</div>
																	<div class="sub_cont">
																		<el-radio-group v-model="globalStyle.submitBtnStyle.textAlign" size="mini">
																			<el-radio-button label="居左"></el-radio-button>
																			<el-radio-button label="居中"></el-radio-button>
																			<el-radio-button label="居右"></el-radio-button>
																		</el-radio-group>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">文字样式</div>
																	<div class="sub_cont text_style">
																		<el-select v-model="globalStyle.submitBtnStyle.fontSize" placeholder="请选择">
																			<template v-for="i in 100">
																				<el-option v-if="i > 11" :label="i" :value="i" :key="i"></el-option>
																			</template>
																		</el-select>
																		<el-color-picker v-model="globalStyle.submitBtnStyle.color" size="mini" show-alpha></el-color-picker>
																		<div class="iconfont icon-zitijiacu" :class="{active: globalStyle.submitBtnStyle.fontWeight == 'bold'}" @click="globalStyle.submitBtnStyle.fontWeight = globalStyle.submitBtnStyle.fontWeight == 'bold'?'normal':'bold'"></div>
																		<div class="iconfont icon-T-qingxie" :class="{active: globalStyle.submitBtnStyle.fontStyle == 'italic'}" @click="globalStyle.submitBtnStyle.fontStyle = globalStyle.submitBtnStyle.fontStyle == 'italic'?'normal':'italic'"></div>
																		<div class="iconfont icon-xiahuaxian" :class="{active: globalStyle.submitBtnStyle.textDecoration == 'underline'}" @click="globalStyle.submitBtnStyle.textDecoration = globalStyle.submitBtnStyle.textDecoration == 'underline'?'none':'underline'"></div>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">边框</div>
																	<div class="sub_cont border_style">
																		<el-input v-model="globalStyle.submitBtnStyle.borderWidth" type="number" min="0" placeholder="请输入内容"></el-input>
																		<el-select v-model="globalStyle.submitBtnStyle.borderStyle" placeholder="请选择">
																			<el-option label="实线" value="solid"></el-option>
																			<el-option label="虚线" value="dashed"></el-option>
																			<el-option label="点线" value="dotted"></el-option>
																		</el-select>
																		<el-color-picker v-model="globalStyle.submitBtnStyle.borderColor" size="mini" show-alpha></el-color-picker>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">圆角</div>
																	<div class="sub_cont silder">
																		<el-slider v-model="globalStyle.submitBtnStyle.borderRadius" :max="200"></el-slider>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">背景</div>
																	<div class="sub_cont">
																		<el-color-picker v-model="globalStyle.submitBtnStyle.backgroundColor" size="mini" show-alpha></el-color-picker>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">外边距</div>
																	<div class="sub_cont">
																		<el-radio-group v-model="submitBtnPaddingFlag" size="mini">
																			<el-radio-button label="上"></el-radio-button>
																			<el-radio-button label="左右"></el-radio-button>
																			<el-radio-button label="下"></el-radio-button>
																		</el-radio-group>
																	</div>
																</div>
																<div class="sub_item" v-show="submitBtnPaddingFlag == '上'">
																	<div class="sub_label">上</div>
																	<div class="sub_cont silder">
																		<el-slider v-model="globalStyle.submitBtnStyle.paddingTop"></el-slider>
																	</div>
																</div>
																<div class="sub_item" v-show="submitBtnPaddingFlag == '左右'">
																	<div class="sub_label">左右</div>
																	<div class="sub_cont silder">
																		<el-slider v-model="globalStyle.submitBtnStyle.paddingHorizontal" :max="500"></el-slider>
																	</div>
																</div>
																<div class="sub_item" v-show="submitBtnPaddingFlag == '下'">
																	<div class="sub_label">下</div>
																	<div class="sub_cont silder">
																		<el-slider v-model="globalStyle.submitBtnStyle.paddingBottom"></el-slider>
																	</div>
																</div>
															</div>
														</transition>
													</div>
													<div class="item">
														<div class="switch_btn">
															<div class="label">
																<span class="txt">内容区</span>
															</div>
															<div class="fold_btn" :class="{active: contFoldFlag}" @click="contFoldFlag = !contFoldFlag">
																<span>{{contFoldFlag?'收起':'展开'}}</span>
																<i class="iconfont icon-zhankai"></i>
															</div>
														</div>
														<transition name="fade">
															<div class="sub_list" v-show="contFoldFlag">
																<div class="sub_item">
																	<div class="sub_label">内边距</div>
																	<div class="sub_cont">
																		<el-radio-group v-model="contPaddingFlag" size="mini">
																			<el-radio-button label="上"></el-radio-button>
																			<el-radio-button label="左右"></el-radio-button>
																			<el-radio-button label="下"></el-radio-button>
																		</el-radio-group>
																	</div>
																</div>
																<div class="sub_item" v-show="contPaddingFlag == '上'">
																	<div class="sub_label">上</div>
																	<div class="sub_cont silder">
																		<el-slider v-model="globalStyle.contStyle.paddingTop"></el-slider>
																	</div>
																</div>
																<div class="sub_item" v-show="contPaddingFlag == '左右'">
																	<div class="sub_label">左右</div>
																	<div class="sub_cont silder">
																		<el-slider v-model="globalStyle.contStyle.paddingHorizontal" :max="200"></el-slider>
																	</div>
																</div>
																<div class="sub_item" v-show="contPaddingFlag == '下'">
																	<div class="sub_label">下</div>
																	<div class="sub_cont silder">
																		<el-slider v-model="globalStyle.contStyle.paddingBottom"></el-slider>
																	</div>
																</div>
															</div>
														</transition>
													</div>
												</div>
											</div>
										</div>
									</div>
								</transition>
							</div>
							<div class="edit_cont mobile" v-show="equipment == 2">
								<div class="edit_wrap">
									<div class="equipment_switch">
										<div class="btn el-icon-monitor" :class="{'active': equipment == 1}" @click="equipment = 1"></div>
										<div class="btn el-icon-mobile" :class="{'active': equipment == 2}" @click="equipment = 2"></div>
									</div>
									<div class="edit_inner m_edit_inner">
										<div class="scroll_inner">
											<div class="mform-head" v-show="globalStyle.formHeader.isShow">
												<div v-show="globalStyle.formHeader.type === '文字'" id="mFormHeadText" class="txt" key="mFormHeadText">{{globalStyle.formHeader.textCont}}</div>
												<div v-show="globalStyle.formHeader.type === '图片'" class="img" key="mFormHeadImg">
													<img v-if="globalStyle.formHeader.imgSrc" :style="{height: mGlobalStyle.formHeader.imgHeight + 'px'}" :src="globalStyle.formHeader.imgSrc">
												</div>
											</div>
											<div class="medit-head">
												<div class="mform-title" id="mFormTitle" v-show="mGlobalStyle.formTitle.isShow">{{globalStyle.formTitle.textCont}}</div>
												<div class="mform-descript" id="mFormDescript" v-show="mGlobalStyle.formDescript.isShow">{{globalStyle.formDescript.textCont}}</div>
											</div>
											<div class="mform-widget">
												<div class="mform-list" v-if="list.length > 0">
													<div class="mform-item" v-for="(item,index) in list" :key="index">
														<mExamWidget :item="item" :globalItemStyle="globalItemStyle" :list="list" :logicFlag="logicFlag"/>
														<div class="cover"></div>
													</div>
												</div>
												<noData v-else text="暂时还没有表单控件哦！"></noData>
											</div>
											<div id="mSubmitBtn" class="submit_btn" v-show="list.length > 0">
												<el-button type="primary">{{mGlobalStyle.submitBtnStyle.text}}</el-button>
											</div>
											<div class="mform-cover" v-if="mGlobalStyle.formCover.isShow">
												<div class="title-cover" v-show="mGlobalStyle.formCover.titleShow">{{globalStyle.formTitle.textCont}}</div>
												<div class="btn-cover">
													<el-button type="primary">{{mGlobalStyle.formCover.btnStyle.text}}</el-button>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="set_area">
									<div class="set_list">
										<div class="set_item">
											<div class="title">手机模式设置</div>
											<div class="list">
												<div class="item">
													<div class="switch_btn">
														<div class="label">
															<span class="txt">封面</span>
														</div>
														<el-switch v-model="mGlobalStyle.formCover.isShow"></el-switch>
													</div>
													<transition name="fade">
														<div class="sub_list" v-show="mGlobalStyle.formCover.isShow">
															<div class="sub_item">
																<div class="sub_label">类型</div>
																<div class="sub_cont">
																	<el-radio-group v-model="mGlobalStyle.formCover.type" size="mini">
																		<el-radio-button label="图片"></el-radio-button>
																		<el-radio-button label="颜色"></el-radio-button>
																	</el-radio-group>
																</div>
															</div>
															<div class="sub_item" v-if="mGlobalStyle.formCover.type === '颜色'">
																<div class="sub_label">背景颜色</div>
																<div class="sub_cont">
																	<el-color-picker v-model="mGlobalStyle.formCover.color" size="mini" show-alpha></el-color-picker>
																</div>
															</div>
															<div class="sub_item" v-if="mGlobalStyle.formCover.type === '图片'">
																<div class="sub_label">选择图片</div>
																<div class="sub_cont">
																	<el-upload class="avatar-uploader el-uploa" action="/com/uplaod_modelimg.do"  accept="image/*" :before-upload="beforeImgUpload" :on-success="handleCoverImgSuccess" :show-file-list="false">
																		<div v-if="mGlobalStyle.formCover.imgSrc" class="avatar">
																				<img :src="mGlobalStyle.formCover.imgSrc">
																				<div class="el-icon-error" @click.stop="mGlobalStyle.formCover.imgSrc = ''"></div>
																			</div>
																			<i v-else class="el-icon-plus avatar-uploader-icon"></i>
																	</el-upload>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">标题显示在封面</div>
																<div class="sub_cont">
																	<el-switch v-model="mGlobalStyle.formCover.titleShow"></el-switch>
																</div>
															</div>
															<template v-if="mGlobalStyle.formCover.titleShow">
																<div class="sub_item">
																	<div class="sub_label">标题位置</div>
																	<div class="sub_cont">
																		<el-radio-group v-model="mGlobalStyle.formCover.titleStyle.align" size="mini">
																			<el-radio-button label="上"></el-radio-button>
																			<el-radio-button label="中"></el-radio-button>
																			<el-radio-button label="下"></el-radio-button>
																		</el-radio-group>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">标题大小</div>
																	<div class="sub_cont text_style">
																		<el-select v-model="mGlobalStyle.formCover.titleStyle.fontSize" placeholder="请选择">
																			<template v-for="i in 100">
																				<el-option v-if="i > 11" :label="i" :value="i" :key="i"></el-option>
																			</template>
																		</el-select>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">文字颜色</div>
																	<div class="sub_cont">
																		<el-color-picker v-model="mGlobalStyle.formCover.titleStyle.color" size="mini" show-alpha></el-color-picker>
																	</div>
																</div>
															</template>
															<div class="sub_item">
																<div class="sub_label">开始方式</div>
																<div class="sub_cont">
																	<el-radio-group v-model="mGlobalStyle.formCover.btnStyle.active" size="mini">
																		<el-radio-button label="开始按钮"></el-radio-button>
																		<el-radio-button label="向上滑动"></el-radio-button>
																	</el-radio-group>
																</div>
															</div>
															<template v-if="mGlobalStyle.formCover.btnStyle.active === '开始按钮'">
																<div class="sub_item">
																	<div class="sub_label">按钮底色</div>
																	<div class="sub_cont">
																		<el-color-picker v-model="mGlobalStyle.formCover.btnStyle.btnBg" size="mini" show-alpha></el-color-picker>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">文字颜色</div>
																	<div class="sub_cont">
																		<el-color-picker v-model="mGlobalStyle.formCover.btnStyle.color" size="mini" show-alpha></el-color-picker>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">按钮文字</div>
																	<div class="sub_cont">
																		<el-input v-model="mGlobalStyle.formCover.btnStyle.text"></el-input>
																	</div>
																</div>
															</template>
														</div>
													</transition>
												</div>
												<div class="item" v-show="globalStyle.formHeader.isShow">
													<div class="switch_btn">
														<div class="label">
															<span class="txt">页眉</span>
														</div>
														<div class="fold_btn" :class="{active: mFormHeadFoldFlag}" @click="mFormHeadFoldFlag = !mFormHeadFoldFlag">
															<span>{{mFormHeadFoldFlag?'收起':'展开'}}</span>
															<i class="iconfont icon-zhankai"></i>
														</div>
													</div>
													<transition name="fade">
														<div class="sub_list" v-show="mFormHeadFoldFlag">
															<template v-if="globalStyle.formHeader.type === '文字'">
																<div class="sub_item">
																	<div class="sub_label">文字样式</div>
																	<div class="sub_cont text_style">
																		<el-select v-model="mGlobalStyle.formHeader.textStyle.fontSize" placeholder="请选择">
																			<template v-for="i in 100">
																				<el-option v-if="i > 11" :label="i" :value="i" :key="i"></el-option>
																			</template>
																		</el-select>
																		<el-color-picker v-model="mGlobalStyle.formHeader.textStyle.color" size="mini" show-alpha></el-color-picker>
																		<div class="iconfont icon-zitijiacu" :class="{active: mGlobalStyle.formHeader.textStyle.fontWeight == 'bold'}" @click="mGlobalStyle.formHeader.textStyle.fontWeight = mGlobalStyle.formHeader.textStyle.fontWeight == 'bold'?'normal':'bold'"></div>
																		<div class="iconfont icon-T-qingxie" :class="{active: mGlobalStyle.formHeader.textStyle.fontStyle == 'italic'}" @click="mGlobalStyle.formHeader.textStyle.fontStyle = mGlobalStyle.formHeader.textStyle.fontStyle == 'italic'?'normal':'italic'"></div>
																		<div class="iconfont icon-xiahuaxian" :class="{active: mGlobalStyle.formHeader.textStyle.textDecoration == 'underline'}" @click="mGlobalStyle.formHeader.textStyle.textDecoration = mGlobalStyle.formHeader.textStyle.textDecoration == 'underline'?'none':'underline'"></div>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">对齐方式</div>
																	<div class="sub_cont">
																		<el-radio-group v-model="mGlobalStyle.formHeader.textStyle.textAlign" size="mini">
																			<el-radio-button label="居左"></el-radio-button>
																			<el-radio-button label="居中"></el-radio-button>
																			<el-radio-button label="居右"></el-radio-button>
																		</el-radio-group>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">背景颜色</div>
																	<div class="sub_cont">
																		<el-color-picker v-model="mGlobalStyle.formHeader.textStyle.backgroundColor" size="mini" show-alpha></el-color-picker>
																	</div>
																</div>
																<div class="sub_item">
																	<div class="sub_label">内边距</div>
																	<div class="sub_cont">
																		<el-radio-group v-model="mFormHeadTextPaddingFlag" size="mini">
																			<el-radio-button label="上下"></el-radio-button>
																			<el-radio-button label="左右"></el-radio-button>
																		</el-radio-group>
																	</div>
																</div>
																<div class="sub_item" v-show="mFormHeadTextPaddingFlag == '上下'">
																	<div class="sub_label">上下</div>
																	<div class="sub_cont silder">
																		<el-slider v-model="mGlobalStyle.formHeader.textStyle.paddingVertical" :max="100"></el-slider>
																	</div>
																</div>
																<div class="sub_item" v-show="mFormHeadTextPaddingFlag == '左右'">
																	<div class="sub_label">左右</div>
																	<div class="sub_cont silder">
																		<el-slider v-model="mGlobalStyle.formHeader.textStyle.paddingHorizontal" :max="100"></el-slider>
																	</div>
																</div>
															</template>
															<template v-else>	
																<div class="sub_item">
																	<div class="sub_label">图片高度</div>
																	<div class="sub_cont silder">
																		<el-slider v-model="mGlobalStyle.formHeader.imgHeight" :min="40" :max="500"></el-slider>
																	</div>
																</div>
															</template>
														</div>
													</transition>
												</div>
												<div class="item">
													<div class="switch_btn">
														<div class="label">
															<span class="txt">背景</span>
														</div>
														<el-switch v-model="mGlobalStyle.formBg.isShow"></el-switch>
													</div>
													<div class="sub_list" v-show="mGlobalStyle.formBg.isShow">
														<div class="sub_item">
															<div class="sub_label">背景类型</div>
															<div class="sub_cont">
																<el-radio-group v-model="mGlobalStyle.formBg.type" size="mini">
																	<el-radio-button label="颜色"></el-radio-button>
																	<el-radio-button label="图片"></el-radio-button>
																	<el-radio-button label="隐藏"></el-radio-button>
																</el-radio-group>
															</div>
														</div>
														<div class="sub_item" v-show="mGlobalStyle.formBg.type == '颜色'">
															<div class="sub_label">颜色</div>
															<div class="sub_cont">
																<el-color-picker v-model="mGlobalStyle.formBg.color" size="mini" show-alpha></el-color-picker>
															</div>
														</div>
														<div class="sub_item" v-show="mGlobalStyle.formBg.type == '图片'">
															<div class="sub_label">图片</div>
															<div class="sub_cont">
																<el-upload class="avatar-uploader" action="/com/uplaod_modelimg.do"  accept="image/*" :before-upload="beforeImgUpload" :on-success="handlemFormBgSuccess" :show-file-list="false">
																	<div v-if="mGlobalStyle.formBg.imgSrc" class="avatar">
																		<img :src="mGlobalStyle.formBg.imgSrc">
																		<div class="el-icon-error" @click.stop="mGlobalStyle.formBg.imgSrc = ''"></div>
																	</div>
																	<i v-else class="el-icon-plus avatar-uploader-icon"></i>
																</el-upload>
															</div>
														</div>
													</div>
												</div>
												<div class="item">
													<div class="switch_btn">
														<div class="label">
															<span class="txt">表单标题</span>
														</div>
														<el-switch v-model="mGlobalStyle.formTitle.isShow"></el-switch>
													</div>
													<transition name="fade">
														<div class="sub_list" v-show="mGlobalStyle.formTitle.isShow">
															<div class="sub_item">
																<div class="sub_label">文字样式</div>
																<div class="sub_cont text_style">
																	<el-select v-model="mGlobalStyle.formTitle.textStyle.fontSize" placeholder="请选择">
																		<template v-for="i in 100">
																			<el-option v-if="i > 11" :label="i" :value="i" :key="i"></el-option>
																		</template>
																	</el-select>
																	<el-color-picker v-model="mGlobalStyle.formTitle.textStyle.color" size="mini" show-alpha></el-color-picker>
																	<div class="iconfont icon-zitijiacu" :class="{active: mGlobalStyle.formTitle.textStyle.fontWeight == 'bold'}" @click="mGlobalStyle.formTitle.textStyle.fontWeight = mGlobalStyle.formTitle.textStyle.fontWeight == 'bold'?'normal':'bold'"></div>
																	<div class="iconfont icon-T-qingxie" :class="{active: mGlobalStyle.formTitle.textStyle.fontStyle == 'italic'}" @click="mGlobalStyle.formTitle.textStyle.fontStyle = mGlobalStyle.formTitle.textStyle.fontStyle == 'italic'?'normal':'italic'"></div>
																	<div class="iconfont icon-xiahuaxian" :class="{active: mGlobalStyle.formTitle.textStyle.textDecoration == 'underline'}" @click="mGlobalStyle.formTitle.textStyle.textDecoration = mGlobalStyle.formTitle.textStyle.textDecoration == 'underline'?'none':'underline'"></div>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">内边距</div>
																<div class="sub_cont">
																	<el-radio-group v-model="mFormTitlePaddingFlag" size="mini">
																		<el-radio-button label="上"></el-radio-button>
																		<el-radio-button label="左右"></el-radio-button>
																		<el-radio-button label="下"></el-radio-button>
																	</el-radio-group>
																</div>
															</div>
															<div class="sub_item" v-show="mFormTitlePaddingFlag == '上'">
																<div class="sub_label">上</div>
																<div class="sub_cont silder">
																	<el-slider v-model="mGlobalStyle.formTitle.textStyle.paddingTop" :max="100"></el-slider>
																</div>
															</div>
															<div class="sub_item" v-show="mFormTitlePaddingFlag == '左右'">
																<div class="sub_label">左右</div>
																<div class="sub_cont silder">
																	<el-slider v-model="mGlobalStyle.formTitle.textStyle.paddingHorizontal" :max="100"></el-slider>
																</div>
															</div>
															<div class="sub_item" v-show="mFormTitlePaddingFlag == '下'">
																<div class="sub_label">下</div>
																<div class="sub_cont silder">
																	<el-slider v-model="mGlobalStyle.formTitle.textStyle.paddingBottom" :max="100"></el-slider>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">背景设置</div>
																<div class="sub_cont">
																	<el-radio-group v-model="mGlobalStyle.formTitle.textStyle.bgType" size="mini">
																		<el-radio-button label="默认"></el-radio-button>
																		<el-radio-button label="颜色"></el-radio-button>
																		<el-radio-button label="图片"></el-radio-button>
																	</el-radio-group>
																</div>
															</div>
															<div class="sub_item" v-show="mGlobalStyle.formTitle.textStyle.bgType == '颜色'">
																<div class="sub_label">选择颜色</div>
																<div class="sub_cont">
																	<el-color-picker v-model="mGlobalStyle.formTitle.textStyle.backgroundColor" size="mini" show-alpha></el-color-picker>
																</div>
															</div>
															<div class="sub_item" v-show="mGlobalStyle.formTitle.textStyle.bgType == '图片'">
																<div class="sub_label">上传图片</div>
																<div class="sub_cont">
																	<el-upload class="avatar-uploader" action="/com/uplaod_modelimg.do"  accept="image/*" :before-upload="beforeImgUpload" :on-success="handlemFormTitleSuccess" :show-file-list="false">
																		<div v-if="mGlobalStyle.formTitle.textStyle.backgroundImage" class="avatar">
																			<img :src="mGlobalStyle.formTitle.textStyle.backgroundImage">
																			<div class="el-icon-error" @click.stop="mGlobalStyle.formTitle.textStyle.backgroundImage = ''"></div>
																		</div>
																		<i v-else class="el-icon-plus avatar-uploader-icon"></i>
																	</el-upload>
																</div>
															</div>
														</div>
													</transition>
												</div>
												<div class="item">
													<div class="switch_btn">
														<div class="label">
															<span class="txt">表单描述</span>
														</div>
														<el-switch v-model="mGlobalStyle.formDescript.isShow"></el-switch>
													</div>
													<transition name="fade">
														<div class="sub_list" v-show="mGlobalStyle.formDescript.isShow">
															<div class="sub_item">
																<div class="sub_label">文字样式</div>
																<div class="sub_cont text_style">
																	<el-select v-model="mGlobalStyle.formDescript.textStyle.fontSize" placeholder="请选择">
																		<template v-for="i in 100">
																			<el-option v-if="i > 11" :label="i" :value="i" :key="i"></el-option>
																		</template>
																	</el-select>
																	<el-color-picker v-model="mGlobalStyle.formDescript.textStyle.color" size="mini" show-alpha></el-color-picker>
																	<div class="iconfont icon-zitijiacu" :class="{active: mGlobalStyle.formDescript.textStyle.fontWeight == 'bold'}" @click="mGlobalStyle.formDescript.textStyle.fontWeight = mGlobalStyle.formDescript.textStyle.fontWeight == 'bold'?'normal':'bold'"></div>
																	<div class="iconfont icon-T-qingxie" :class="{active: mGlobalStyle.formDescript.textStyle.fontStyle == 'italic'}" @click="mGlobalStyle.formDescript.textStyle.fontStyle = mGlobalStyle.formDescript.textStyle.fontStyle == 'italic'?'normal':'italic'"></div>
																	<div class="iconfont icon-xiahuaxian" :class="{active: mGlobalStyle.formDescript.textStyle.textDecoration == 'underline'}" @click="mGlobalStyle.formDescript.textStyle.textDecoration = mGlobalStyle.formDescript.textStyle.textDecoration == 'underline'?'none':'underline'"></div>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">内边距</div>
																<div class="sub_cont">
																	<el-radio-group v-model="mFormDescriptPaddingFlag" size="mini">
																		<el-radio-button label="上下"></el-radio-button>
																		<el-radio-button label="左右"></el-radio-button>
																	</el-radio-group>
																</div>
															</div>
															<div class="sub_item" v-show="mFormDescriptPaddingFlag == '上下'">
																<div class="sub_label">上下</div>
																<div class="sub_cont silder">
																	<el-slider v-model="mGlobalStyle.formDescript.textStyle.paddingVertical" :max="100"></el-slider>
																</div>
															</div>
															<div class="sub_item" v-show="mFormDescriptPaddingFlag == '左右'">
																<div class="sub_label">左右</div>
																<div class="sub_cont silder">
																	<el-slider v-model="mGlobalStyle.formDescript.textStyle.paddingHorizontal" :max="100"></el-slider>
																</div>
															</div>
														</div>
													</transition>
												</div>
												<div class="item">
													<div class="switch_btn">
														<div class="label">
															<span class="txt">表单项题干样式</span>
														</div>
														<div class="fold_btn" :class="{active: mLabelFoldFlag}" @click="mLabelFoldFlag = !mLabelFoldFlag">
															<span>{{mLabelFoldFlag?'收起':'展开'}}</span>
															<i class="iconfont icon-zhankai"></i>
														</div>
													</div>
													<transition name="fade">
														<div class="sub_list" v-show="mLabelFoldFlag">												
															<div class="sub_item">
																<div class="sub_label">文字样式</div>
																<div class="sub_cont text_style">
																	<el-select v-model="globalItemStyle.mLabelStyle.fontSize" placeholder="请选择">
																		<template v-for="i in 100">
																			<el-option v-if="i > 11" :label="i" :value="i" :key="i"></el-option>
																		</template>
																	</el-select>
																	<el-color-picker v-model="globalItemStyle.mLabelStyle.color" size="mini" show-alpha></el-color-picker>
																	<div class="iconfont icon-zitijiacu" :class="{active: globalItemStyle.mLabelStyle.fontWeight == 'bold'}" @click="globalItemStyle.mLabelStyle.fontWeight = globalItemStyle.mLabelStyle.fontWeight == 'bold'?'normal':'bold'"></div>
																	<div class="iconfont icon-T-qingxie" :class="{active: globalItemStyle.mLabelStyle.fontStyle == 'italic'}" @click="globalItemStyle.mLabelStyle.fontStyle = globalItemStyle.mLabelStyle.fontStyle == 'italic'?'normal':'italic'"></div>
																	<div class="iconfont icon-xiahuaxian" :class="{active: globalItemStyle.mLabelStyle.textDecoration == 'underline'}" @click="globalItemStyle.mLabelStyle.textDecoration = globalItemStyle.mLabelStyle.textDecoration == 'underline'?'none':'underline'"></div>
																</div>
															</div>
														</div>
													</transition>
												</div>
												<div class="item">
													<div class="switch_btn">
														<div class="label">
															<span class="txt">表单选项样式</span>
														</div>
														<div class="fold_btn" :class="{active: mItemFoldFlag}" @click="mItemFoldFlag = !mItemFoldFlag">
															<span>{{mItemFoldFlag?'收起':'展开'}}</span>
															<i class="iconfont icon-zhankai"></i>
														</div>
													</div>
													<transition name="fade">
														<div class="sub_list" v-show="mItemFoldFlag">
															<div class="sub_item">
																<div class="sub_label">文字样式</div>
																<div class="sub_cont text_style">
																	<el-select v-model="globalItemStyle.mItemStyle.fontSize" placeholder="请选择">
																		<template v-for="i in 100">
																			<el-option v-if="i > 11" :label="i" :value="i" :key="i"></el-option>
																		</template>
																	</el-select>
																	<el-color-picker v-model="globalItemStyle.mItemStyle.color" size="mini" show-alpha></el-color-picker>
																	<div class="iconfont icon-zitijiacu" :class="{active: globalItemStyle.mItemStyle.fontWeight == 'bold'}" @click="globalItemStyle.mItemStyle.fontWeight = globalItemStyle.mItemStyle.fontWeight == 'bold'?'normal':'bold'"></div>
																	<div class="iconfont icon-T-qingxie" :class="{active: globalItemStyle.mItemStyle.fontStyle == 'italic'}" @click="globalItemStyle.mItemStyle.fontStyle = globalItemStyle.mItemStyle.fontStyle == 'italic'?'normal':'italic'"></div>
																	<div class="iconfont icon-xiahuaxian" :class="{active: globalItemStyle.mItemStyle.textDecoration == 'underline'}" @click="globalItemStyle.mItemStyle.textDecoration = globalItemStyle.mItemStyle.textDecoration == 'underline'?'none':'underline'"></div>
																</div>
															</div>
														</div>
													</transition>
												</div>
												<div class="item">
													<div class="switch_btn">
														<div class="label">
															<span class="txt">提交按钮</span>
														</div>
														<div class="fold_btn" :class="{active: mSubmitBtnFoldFlag}" @click="mSubmitBtnFoldFlag = !mSubmitBtnFoldFlag">
															<span>{{mSubmitBtnFoldFlag?'收起':'展开'}}</span>
															<i class="iconfont icon-zhankai"></i>
														</div>
													</div>
													<transition name="fade">
														<div class="sub_list" v-show="mSubmitBtnFoldFlag">
															<div class="sub_item">
																<div class="sub_label">按钮文字</div>
																<div class="sub_cont">
																	<el-input :class="{'error': mGlobalStyle.submitBtnStyle.isError}" v-model="mGlobalStyle.submitBtnStyle.text" maxlength="10" placeholder="请输入按钮文字"></el-input>
																</div>
															</div>`
															<div class="sub_item">
																<div class="sub_label">按钮宽度</div>
																<div class="sub_cont silder">
																	<el-slider v-model="mGlobalStyle.submitBtnStyle.width" :max="375" :min="40"></el-slider>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">按钮高度</div>
																<div class="sub_cont silder">
																	<el-slider v-model="mGlobalStyle.submitBtnStyle.height" :min="20"></el-slider>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">按钮位置</div>
																<div class="sub_cont">
																	<el-radio-group v-model="mGlobalStyle.submitBtnStyle.textAlign" size="mini">
																		<el-radio-button label="居左"></el-radio-button>
																		<el-radio-button label="居中"></el-radio-button>
																		<el-radio-button label="居右"></el-radio-button>
																	</el-radio-group>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">文字样式</div>
																<div class="sub_cont text_style">
																	<el-select v-model="mGlobalStyle.submitBtnStyle.fontSize" placeholder="请选择">
																		<template v-for="i in 100">
																			<el-option v-if="i > 11" :label="i" :value="i" :key="i"></el-option>
																		</template>
																	</el-select>
																	<el-color-picker v-model="mGlobalStyle.submitBtnStyle.color" size="mini" show-alpha></el-color-picker>
																	<div class="iconfont icon-zitijiacu" :class="{active: mGlobalStyle.submitBtnStyle.fontWeight == 'bold'}" @click="mGlobalStyle.submitBtnStyle.fontWeight = mGlobalStyle.submitBtnStyle.fontWeight == 'bold'?'normal':'bold'"></div>
																	<div class="iconfont icon-T-qingxie" :class="{active: mGlobalStyle.submitBtnStyle.fontStyle == 'italic'}" @click="mGlobalStyle.submitBtnStyle.fontStyle = mGlobalStyle.submitBtnStyle.fontStyle == 'italic'?'normal':'italic'"></div>
																	<div class="iconfont icon-xiahuaxian" :class="{active: mGlobalStyle.submitBtnStyle.textDecoration == 'underline'}" @click="mGlobalStyle.submitBtnStyle.textDecoration = mGlobalStyle.submitBtnStyle.textDecoration == 'underline'?'none':'underline'"></div>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">边框</div>
																<div class="sub_cont border_style">
																	<el-input v-model="mGlobalStyle.submitBtnStyle.borderWidth" type="number" min="0" placeholder="请输入内容"></el-input>
																	<el-select v-model="mGlobalStyle.submitBtnStyle.borderStyle" placeholder="请选择">
																		<el-option label="实线" value="solid"></el-option>
																		<el-option label="虚线" value="dashed"></el-option>
																		<el-option label="点线" value="dotted"></el-option>
																	</el-select>
																	<el-color-picker v-model="mGlobalStyle.submitBtnStyle.borderColor" size="mini" show-alpha></el-color-picker>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">圆角</div>
																<div class="sub_cont silder">
																	<el-slider v-model="mGlobalStyle.submitBtnStyle.borderRadius" :max="50"></el-slider>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">背景</div>
																<div class="sub_cont">
																	<el-color-picker v-model="mGlobalStyle.submitBtnStyle.backgroundColor" size="mini" show-alpha></el-color-picker>
																</div>
															</div>
															<div class="sub_item">
																<div class="sub_label">外边距</div>
																<div class="sub_cont">
																	<el-radio-group v-model="mSubmitBtnPaddingFlag" size="mini">
																		<el-radio-button label="上"></el-radio-button>
																		<el-radio-button label="左右"></el-radio-button>
																		<el-radio-button label="下"></el-radio-button>
																	</el-radio-group>
																</div>
															</div>
															<div class="sub_item" v-show="mSubmitBtnPaddingFlag == '上'">
																<div class="sub_label">上</div>
																<div class="sub_cont silder">
																	<el-slider v-model="mGlobalStyle.submitBtnStyle.paddingTop"></el-slider>
																</div>
															</div>
															<div class="sub_item" v-show="mSubmitBtnPaddingFlag == '左右'">
																<div class="sub_label">左右</div>
																<div class="sub_cont silder">
																	<el-slider v-model="mGlobalStyle.submitBtnStyle.paddingHorizontal" :max="500"></el-slider>
																</div>
															</div>
															<div class="sub_item" v-show="mSubmitBtnPaddingFlag == '下'">
																<div class="sub_label">下</div>
																<div class="sub_cont silder">
																	<el-slider v-model="mGlobalStyle.submitBtnStyle.paddingBottom"></el-slider>
																</div>
															</div>
														</div>
													</transition>
												</div>
												<div class="item">
													<div class="switch_btn">
														<div class="label">
															<span class="txt">内容区</span>
														</div>
														<div class="fold_btn" :class="{active: mContFoldFlag}" @click="mContFoldFlag = !mContFoldFlag">
															<span>{{mContFoldFlag?'收起':'展开'}}</span>
															<i class="iconfont icon-zhankai"></i>
														</div>
													</div>
													<transition name="fade">
														<div class="sub_list" v-show="mContFoldFlag">
															<div class="sub_item">
																<div class="sub_label">内边距</div>
																<div class="sub_cont">
																	<el-radio-group v-model="mContPaddingFlag" size="mini">
																		<el-radio-button label="上"></el-radio-button>
																		<el-radio-button label="左右"></el-radio-button>
																		<el-radio-button label="下"></el-radio-button>
																	</el-radio-group>
																</div>
															</div>
															<div class="sub_item" v-show="mContPaddingFlag == '上'">
																<div class="sub_label">上</div>
																<div class="sub_cont silder">
																	<el-slider v-model="mGlobalStyle.contStyle.paddingTop"></el-slider>
																</div>
															</div>
															<div class="sub_item" v-show="mContPaddingFlag == '左右'">
																<div class="sub_label">左右</div>
																<div class="sub_cont silder">
																	<el-slider v-model="mGlobalStyle.contStyle.paddingHorizontal" :max="100"></el-slider>
																</div>
															</div>
															<div class="sub_item" v-show="mContPaddingFlag == '下'">
																<div class="sub_label">下</div>
																<div class="sub_cont silder">
																	<el-slider v-model="mGlobalStyle.contStyle.paddingBottom"></el-slider>
																</div>
															</div>
														</div>
													</transition>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>	
			</div>
		</div>
	</div>
</template>
<script>
import $ from "jquery";
import designHeader from "@/components/designHeader.vue";
import designLeftNav from "@/components/designLeftNav.vue";
import noData from "@/components/noData";
import examWidget from "@/components/examWidget";
import mExamWidget from "@/components/mExamWidget";
import examTopicEdit from "@/components/examTopicEdit";
import logicDialog from "@/components/logicDialog";
import draggable from "vuedraggable";
import { get,post } from "/src/services/ajax_ucenter.js";
export default {
	name: "examtionTypes",
	components: {
		designHeader,
		designLeftNav,
		noData,
		draggable,
		examWidget,
		mExamWidget,
		examTopicEdit,
		logicDialog
	},
	props:{
		logicFlag:{
			type:Boolean
		}
	},
	data() {
		return {
			formToken:sessionStorage.getItem('token')?sessionStorage.getItem('token'):'',
			identityType:sessionStorage.getItem('identityType')?sessionStorage.getItem('identityType'):'',
			fromType:'',
			formTypeId:'',
			formId: '',
			widgetGroupList:[],
			userId:'',
			useFlag:false,
			scoreGross:0,
			showBtn:true,
			hasEdit:0,
			isSaving:false,
			isJz: sessionStorage.getItem('isJz')?true:false,
			globalStyle: {
				themeColor: "#0cf",
				formWidth: 1100,
				formHeader: {
					isShow: false,
					type: "文字",
					textCont: "瑞蚁云表单",
					textStyle: {
						fontSize: 14,
						color: "#333",
						fontWeight: "normal",
						fontStyle: "normal",
						textDecoration: "none",
						textAlign: "居左",
						backgroundColor: "#EFF4FE",
						paddingVertical: 10,
						paddingHorizontal: 20,
					},
					imgSrc: "",
					imgHeight: 300
				},
				formBg: {
					isShow: false,
					type: '隐藏',
					color: '#fff',
					imgSrc: '',
					position:'平铺',
					contentBgType:'颜色',
					contentBgColor:"#fff"
				},
				formLogo: {
					isShow: false,
					imgSrc: '',
					align:'左对齐'
				},
				formTitle: {
					isShow: true,
					textCont: "测评标题",
					textStyle: {
						fontSize: 20,
						color: "#333",
						fontWeight: "normal",
						fontStyle: "normal",
						textDecoration: "none",
						bgType: "默认",
						backgroundColor: "#fff",
						backgroundImage: "",
						paddingTop: 30,
						paddingHorizontal: 30,
						paddingBottom: 30,
					},
				},
				formDescript: {
					isShow: false,
					textCont: "为了给您提供更好的服务，希望您能抽出几分钟时间，将您的感受和建议告诉我们，我们非常重视每位用户的宝贵意见，期待您的参与！现在我们就马上开始吧！",
					textStyle: {
						fontSize: 14,
						color: "#999",
						fontWeight: "normal",
						fontStyle: "normal",
						textDecoration: "none",
						paddingVertical: 5,
						paddingHorizontal: 30,
					},
				},
				contStyle:{
					paddingTop: 20,
					paddingHorizontal: 30,
					paddingBottom: 20,
				},
				submitBtnStyle: {
					width: 100,
					height: 40,
					text: "提交",
					fontSize: 14,
					color: "#fff",
					fontWeight: "normal",
					fontStyle: "normal",
					textDecoration: "none",
					borderWidth: 1,
					borderStyle: "solid",
					borderColor: "",
					borderRadius: 4,
					backgroundColor: "",
					textAlign: "居中",
					paddingTop: 10,
					paddingHorizontal: 0,
					paddingBottom: 30,
				},
			},
			globalItemStyle: {
				itemStyle: {
					fontSize: 14,
					color: "#333",
					fontWeight: "normal",
					fontStyle: "normal",
					textDecoration: "none"
				},
				labelStyle: {
					fontSize: 16,
					color: "#333",
					fontWeight: "normal",
					fontStyle: "normal",
					textDecoration: "none"
				},
				mItemStyle: {
					fontSize: 12,
					color: "#333",
					fontWeight: "normal",
					fontStyle: "normal",
					textDecoration: "none",
				},
				mLabelStyle: {
					fontSize: 12,
					color: "#333",
					fontWeight: "normal",
					fontStyle: "normal",
					textDecoration: "none",
				},
			},
			mGlobalStyle: {
				formCover: {
					isShow: false,
					type: "颜色",
					color:'#fff',
					imgSrc: "",
					titleShow:false,
					titleStyle: {
						fontSize: 14,
						color: "#333",
						align: "上",
					},
					btnStyle:{
						active:'开始按钮',
						btnBg:'#0cf',
						color:'#fff',
						text:'开始'
					}
				},
				formHeader: {
					isShow: false,
					type: "文字",
					textCont: "瑞蚁云表单",
					textStyle: {
						fontSize: 14,
						color: "#333",
						fontWeight: "normal",
						fontStyle: "normal",
						textDecoration: "none",
						textAlign: "居左",
						backgroundColor: "#EFF4FE",
						paddingVertical: 10,
						paddingHorizontal: 20,
					},
					imgSrc: "",
					imgHeight: 150
				},
				formBg: {
					isShow: false,
					type: '隐藏',
					color: '#fff',
					imgSrc: '',
					position:'平铺',
					contentBgType:'颜色',
					contentBgColor:"#fff"
				},
				formTitle: {
					isShow: true,
					textStyle: {
						fontSize: 20,
						color: "#333",
						fontWeight: "normal",
						fontStyle: "normal",
						textDecoration: "none",
						bgType: "默认",
						backgroundColor: "#fff",
						backgroundImage: "",
						paddingTop: 30,
						paddingHorizontal:10,
						paddingBottom: 10,
					},
				},
				formDescript: {
					isShow: false,
					textStyle: {
						fontSize: 14,
						color: "#999",
						fontWeight: "normal",
						fontStyle: "normal",
						textDecoration: "none",
						paddingVertical: 5,
						paddingHorizontal: 10,
					},
				},
				contStyle:{
					paddingTop: 10,
					paddingHorizontal: 10,
					paddingBottom: 10,
				},
				submitBtnStyle: {
					text:'提交',
					width: 100,
					height: 40,
					fontSize: 14,
					color: "#fff",
					fontWeight: "normal",
					fontStyle: "normal",
					textDecoration: "none",
					borderWidth: 1,
					borderStyle: "solid",
					borderColor: "",
					borderRadius: 4,
					backgroundColor: "",
					textAlign: "居中",
					paddingTop: 10,
					paddingHorizontal: 0,
					paddingBottom: 30,
				},
			},
			list: [],
			// 临时数据 用于页面展示 不需要提交
			widgetList: [],
			equipment: 1,
			tabNavCurrent: 0,
			setShow: true,
			headerImgTypeList: [],
			headerImgTypeId: "",
			headerImgList: [],
			formTitleTips: false,
			formDescriptTips: false,
			isDragging: false,
			currentWidget: '',
			dragTipsFlag: true,
			submitBtnFoldFlag: false,
			contFoldFlag: false,
			itemFoldFlag: false,
			labelFoldFlag: false,
			submitBtnPaddingFlag: "上",
			contPaddingFlag: "上",
			itemPaddingFlag: "上下",
			formHeadTextPaddingFlag: "上下",
			formTitlePaddingFlag: "上",
			formDescriptPaddingFlag: "上下",
			headerImageListShowFlag: false,
			mFormHeadFoldFlag: false,
			mFormHeadTextPaddingFlag: "上下",
			mFormTitleFoldFlag: false,
			mFormTitlePaddingFlag: "上",
			mFormDescriptFoldFlag: false,
			mFormDescriptPaddingFlag: "上下",
			mContFoldFlag: false,
			mContPaddingFlag: "上",
			mItemFoldFlag: false,
			mItemPaddingFlag: "上下",
			mLabelFoldFlag: false,
			mInputFoldFlag: false,
			mSubmitBtnFoldFlag: false,
			mSubmitBtnPaddingFlag: "上",
			watchFlag: 0,
			activeNames:0
		};
	},
	computed:{
		watchObj(){
			const obj = {
				globalStyle: this.globalStyle,
				mGlobalStyle: this.mGlobalStyle,
				list: this.list
			}
			return obj;
		},
	},
	watch: {
		"globalStyle.themeColor": {
			handler() {
				document.documentElement.style.setProperty("--theme-custom-color",this.globalStyle.themeColor);
			},
		},
		"globalStyle.submitBtnStyle": {
			handler() {
				const styleObj = this.globalStyle.submitBtnStyle;
				$("#submitBtn .el-button").css({
					width: styleObj.width + "px",
					height: styleObj.height + "px",
					borderWidth: styleObj.borderWidth + "px",
					borderStyle: styleObj.borderStyle,
					borderColor: styleObj.borderColor,
					borderRadius: styleObj.borderRadius + "px",
					backgroundColor: styleObj.backgroundColor,
					fontSize: styleObj.fontSize + "px",
					fontWeight: styleObj.fontWeight,
					fontStyle: styleObj.fontStyle,
					color: styleObj.color,
					textDecoration: styleObj.textDecoration,
				});
				$("#submitBtn").css({
					padding: styleObj.paddingTop + "px " + styleObj.paddingHorizontal + "px " + styleObj.paddingBottom + 'px',
					justifyContent: styleObj.textAlign == '居左'?'flex-start':styleObj.textAlign == '居中'?'center':'flex-end',
				});
				if(styleObj.text == ''){
					styleObj.isError = true;
				}else{
					styleObj.isError = false;
				}
			},
			deep: true,
			immediate: true,
		},
		"globalStyle.formHeader": {
			handler() {
				const formHeader = this.globalStyle.formHeader;
				if(!(formHeader.isShow && formHeader.type == '图片')){
					this.headerImageListShowFlag = false;
				}
				this.$nextTick(function () {
					let styleObj = this.globalStyle.formHeader.textStyle;
					$("#formHeadText").css({
						fontSize: styleObj.fontSize + "px",
						color: styleObj.color,
						fontWeight: styleObj.fontWeight,
						fontStyle: styleObj.fontStyle,
						textDecoration: styleObj.textDecoration,
						textAlign: styleObj.textAlign == "居左"? "left":styleObj.textAlign == "居中"?"center":"right",
						backgroundColor: styleObj.backgroundColor,
						padding: styleObj.paddingVertical + "px " + styleObj.paddingHorizontal + "px",
					});
				});
				if(formHeader.isShow && formHeader.type == '文字' && formHeader.textCont == ''){
					formHeader.isError = true;
				}else{
					formHeader.isError = false;
				}
			},
			deep: true,
			immediate: true,
		},
		"globalStyle.formTitle": {
			handler() {
				const formTitle = this.globalStyle.formTitle;
				this.$nextTick(function () {
					let styleObj = this.globalStyle.formTitle.textStyle;
					let backgroundStr = styleObj.bgType == "默认"? "unset":styleObj.bgType == "颜色"? styleObj.backgroundColor:"url(" + styleObj.backgroundImage + ")";
					$("#formTitle .el-textarea__inner").css({
						fontSize: styleObj.fontSize + "px",
						color: styleObj.color,
						fontWeight: styleObj.fontWeight,
						fontStyle: styleObj.fontStyle,
						textDecoration: styleObj.textDecoration,
						padding: styleObj.paddingTop + "px " + styleObj.paddingHorizontal + "px " + styleObj.paddingBottom + 'px',
						background: backgroundStr,
						backgroundSize: 'cover',
						backgroundPosition: 'center'
					});
					this.$refs.titleText.resizeTextarea();
				});
				if(formTitle.isShow && formTitle.textCont == ''){
					formTitle.isError = true;
				}else{
					formTitle.isError = false;
				}
			},
			deep: true,
			immediate: true,
		},
		"globalStyle.formDescript": {
			handler() {
				const formDescript = this.globalStyle.formDescript;
				this.$nextTick(function () {
					let styleObj = this.globalStyle.formDescript.textStyle;
					$("#formDescript .el-textarea__inner").css({
						fontSize: styleObj.fontSize + "px",
						color: styleObj.color,
						fontWeight: styleObj.fontWeight,
						fontStyle: styleObj.fontStyle,
						textDecoration: styleObj.textDecoration,
						padding: styleObj.paddingVertical + "px " + styleObj.paddingHorizontal + "px",
					});
					this.$refs.descriptText.resizeTextarea(); 
				});	
				if(formDescript.isShow && formDescript.textCont == ''){
					formDescript.isError = true;
				}else{
					formDescript.isError = false;
				}
			},
			deep: true,
			immediate: true,
		},
		"globalStyle.contStyle": {
			handler() {
				const styleObj = this.globalStyle.contStyle;
				$(".parent-wrap").css({
					padding: styleObj.paddingTop + "px " + styleObj.paddingHorizontal + "px " + styleObj.paddingBottom + 'px',
				});
			},
			deep: true,
			immediate: true,
		},
		"globalStyle.formBg": {
			handler() {
				const styleObj = this.globalStyle.formBg;
				let backgroundStr = '';
				if(styleObj.isShow){
					if(styleObj.type == '颜色'){
						backgroundStr = styleObj.color;
					}else if(styleObj.type == '隐藏'){
						backgroundStr = 'none';
					}else{
						if(styleObj.imgSrc){
							if(styleObj.position == '平铺'){
								backgroundStr = 'url(' + styleObj.imgSrc + ') center';
							}else{
								backgroundStr = 'url(' + styleObj.imgSrc + ') no-repeat center';
							}
						}
					}
					if(styleObj.contentBgType == '颜色'){
						$(".edit_inner:not(.m_edit_inner)").css({
							background: styleObj.contentBgColor
						});
					}else{
						$(".edit_inner:not(.m_edit_inner)").css({
							background: 'none'
						});
					}
				}else{
					backgroundStr = 'none';
				}
				$(".form_page").css({
					background: backgroundStr
				});
				
			},
			deep: true,
			immediate: true,
		},
		"mGlobalStyle.formCover": {
			handler() {
				this.$nextTick(function () {
					let styleObj = this.mGlobalStyle.formCover;
					let btnObj = this.mGlobalStyle.formCover.btnStyle;
					if(styleObj.isShow){
						$(".scroll_inner")[0].scrollTop = 0;
						$(".scroll_inner").css({
							"overflow":"hidden"
						})
						if(styleObj.type == '颜色'){
							$(".mform-cover").css({
								background: styleObj.color
							});
						}else{
							if(styleObj.imgSrc){
								$(".mform-cover").css({
									background: 'url(' + styleObj.imgSrc + ')',
									backgroundSize:'cover'
								});
							}
							
						}
						$(".mform-cover").css({
							color:styleObj.titleStyle.color,
							fontSize: styleObj.titleStyle.fontSize+'px',
						});
						if(btnObj.active == '开始按钮' ){
							$(".mform-cover .btn-cover .el-button").css({
								color: btnObj.color,
								backgroundColor: btnObj.btnBg,
								borderColor: btnObj.btnBg,
							});
						}
						if(styleObj.titleShow){
							$(".mform-cover .title-cover").css({
								top:styleObj.titleStyle.align == '上'? '20%':styleObj.titleStyle.align == '中'?'40%':'70%'
							});
						}

					}else{
						$(".scroll_inner").css({
							"overflow-y":"auto"
						})
					}
						
					
					
					
				});
			},
			deep: true,
			immediate: true,
		},
		"mGlobalStyle.formHeader": {
			handler() {
				this.$nextTick(function () {
					let styleObj = this.mGlobalStyle.formHeader.textStyle;
					$("#mFormHeadText").css({
						fontSize: styleObj.fontSize + "px",
						color: styleObj.color,
						fontWeight: styleObj.fontWeight,
						fontStyle: styleObj.fontStyle,
						textDecoration: styleObj.textDecoration,
						textAlign: styleObj.textAlign == "居左"? "left":styleObj.textAlign == "居中"?"center":"right",
						backgroundColor: styleObj.backgroundColor,
						padding: styleObj.paddingVertical + "px " + styleObj.paddingHorizontal + "px",
					});
				});
			},
			deep: true,
			immediate: true,
		},
		"mGlobalStyle.formTitle": {
			handler() {
				this.$nextTick(function () {
					let styleObj = this.mGlobalStyle.formTitle.textStyle;
					let backgroundStr = styleObj.bgType == "默认"? "unset":styleObj.bgType == "颜色"? styleObj.backgroundColor:"url(" + styleObj.backgroundImage + ")";
					$("#mFormTitle").css({
						fontSize: styleObj.fontSize + "px",
						color: styleObj.color,
						fontWeight: styleObj.fontWeight,
						fontStyle: styleObj.fontStyle,
						textDecoration: styleObj.textDecoration,
						padding: styleObj.paddingTop + "px " + styleObj.paddingHorizontal + "px " + styleObj.paddingBottom + 'px',
						background: backgroundStr,
						backgroundSize: 'cover',
						backgroundPosition: 'center'
					});
				});
			},
			deep: true,
			immediate: true,
		},
		"mGlobalStyle.formDescript": {
			handler() {
				this.$nextTick(function () {
					let styleObj = this.mGlobalStyle.formDescript.textStyle;
					$("#mFormDescript").css({
						fontSize: styleObj.fontSize + "px",
						color: styleObj.color,
						fontWeight: styleObj.fontWeight,
						fontStyle: styleObj.fontStyle,
						textDecoration: styleObj.textDecoration,
						padding: styleObj.paddingVertical + "px " + styleObj.paddingHorizontal + "px",
					});
				});	
			},
			deep: true,
			immediate: true,
		},
		"mGlobalStyle.contStyle": {
			handler() {
				const styleObj = this.mGlobalStyle.contStyle;
				$(".mform-widget").css({
					padding: styleObj.paddingTop + "px " + styleObj.paddingHorizontal + "px " + styleObj.paddingBottom + 'px',
				});
			},
			deep: true,
			immediate: true,
		},
		"mGlobalStyle.formBg": {
			handler() {
				const styleObj = this.mGlobalStyle.formBg;
				let backgroundStr = '';
				if(styleObj.isShow){
					if(styleObj.type == '颜色'){
						backgroundStr = styleObj.color;
					}else if(styleObj.type == '隐藏'){
						if(styleObj.imgSrc){
							backgroundStr = 'none';
						}
					}else{
						if(styleObj.imgSrc){
							if(styleObj.position == '平铺'){
								backgroundStr = 'url(' + styleObj.imgSrc + ') center';
							}else{
								backgroundStr = 'url(' + styleObj.imgSrc + ') no-repeat center';
							}
							
						}
					}
				}else{
					backgroundStr = '#fff';
				}
				$(".m_edit_inner .scroll_inner").css({
					background: backgroundStr,
					// backgroundSize: 'cover'
				});
			},
			deep: true,
			immediate: true,
		},
		"mGlobalStyle.submitBtnStyle": {
			handler() {
				const styleObj = this.mGlobalStyle.submitBtnStyle;
				$("#mSubmitBtn .el-button").css({
					text: styleObj.text ,
					width: styleObj.width + "px",
					height: styleObj.height + "px",
					borderWidth: styleObj.borderWidth + "px",
					borderStyle: styleObj.borderStyle,
					borderColor: styleObj.borderColor,
					borderRadius: styleObj.borderRadius + "px",
					backgroundColor: styleObj.backgroundColor,
					fontSize: styleObj.fontSize + "px",
					fontWeight: styleObj.fontWeight,
					fontStyle: styleObj.fontStyle,
					color: styleObj.color,
					textDecoration: styleObj.textDecoration,
				});
				$("#mSubmitBtn").css({
					padding: styleObj.paddingTop + "px " + styleObj.paddingHorizontal + "px " + styleObj.paddingBottom + 'px',
					justifyContent: styleObj.textAlign == '居左'?'flex-start':styleObj.textAlign == '居中'?'center':'flex-end',
				});
			},
			deep: true,
			immediate: true,
		},
		"headerImgTypeId": {
			handler() {
				this.getHeaderImgList();
			},
			deep: true,
			//immediate: true,
		},
		list: {
			handler() {
				this.scoreGross = 0;
				this.list.forEach((o,index) => {
					o.sort = index
					if(o.scoreGross){
						this.scoreGross = parseInt(o.scoreGross) + parseInt(this.scoreGross)
					}
				});
				console.log(this.scoreGross)
			},
			deep: true,
			immediate: true,
		},
		watchFlag(val){
			this.hasEdit = val;
		}
	},
	created(){
		const {authToken,identityType,formId} = this.$route.query;
		this.identityType = identityType?identityType:sessionStorage.getItem('identityType');
		this.formId = formId?formId:sessionStorage.getItem('formId');
		this.fromType = this.$route.query.fromType?this.$route.query.fromType:sessionStorage.getItem('fromType')?sessionStorage.getItem('fromType'):'';
		this.formTypeId = this.$route.query.formTypeId?this.$route.query.formTypeId:sessionStorage.getItem('formTypeId')?sessionStorage.getItem('formTypeId'):'';
		sessionStorage.setItem('fromType',this.fromType);
		sessionStorage.setItem('formTypeId',this.formTypeId);
		sessionStorage.setItem('identityType',this.identityType);
		if(!this.formToken && identityType == 'user'){
			// 校验登录状态
			if(authToken && identityType){
				this.auth(authToken,identityType);
				sessionStorage.setItem('identityType',identityType);
			}else{
				this.$router.push('/login')
			}
		}else{
			if(this.identityType == 'admin'){
				this.getFormTpl();
				sessionStorage.setItem('formId',this.formId)
			}else{
				this.userId = this.$route.query.userId?this.$route.query.userId:'';
				if(this.userId){
					this.useFlag = true;
					this.getTplFormEdit(this.formId,this.userId)
				}else if(this.formId){
					this.getFormDetail(this.formId);
					sessionStorage.setItem('formId',this.formId)
				}else{
					this.getWidgets(this.formTypeId);
				}
			}
		}
	},
	methods: {
		// 登录
		auth(authToken,identityType){
			const _this = this;
			post('/com/auth.api',{authToken,identityType}).then(res => {
				if(res.code == 200){
					sessionStorage.setItem('token',res.data.form_token);
					_this.formToken = res.data.form_token;
					if(this.formId){
						this.getFormDetail(this.formId);
						sessionStorage.setItem('formId',this.formId)
					}else{
						this.getWidgets(this.formTypeId);
					}
					//location.reload();
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 编辑表单先暂停发布
		stopPublish(formId){
			const _this = this;
			this.$confirm('修改后您收集的关于该表单的所有数据将背删除！确定继续？', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				post('/formadmin/stop_publish.jhtml',{formId}).then(res => {
					if(res.code == 200){
						_this.$message.success('暂停发布成功！');
					}else{
						_this.$message.error(res.message);
					}
				}).catch(err => {
					_this.$message.error(err);
				})
			}).catch(() => {
				this.$router.back()
				// return false;
			});
		},
		// 加载的控件统一添加必需字段
		changeWidgetGroup(list,n){
			const _this = this;
			if(n){
				_this.widgetList = [];
			}
			list.forEach(widgetGroup=>{
				let group = [];
				widgetGroup.question.forEach(widget=>{
					let item = {};
					item = {
						...widget,
						jumpShow:true,
						logicShow:true,
						logicFlag:false,
						logicDialog:false,
						logicJumps:[],
						showLogicCondition:1,
						showLogic:[],
						topicScore:''
					}
					item.editState = false;
					if(item.topic_required == '1' || item.topic_required == 'true'){
						item.topic_required = true;
					}else{
						item.topic_required = false;
					}
					item.topicOption = item.topicOption && JSON.parse(item.topicOption);
					if(item.modelType == 'checked' || item.modelType == 'imagesChecked'){
						item.answer = [];
						item.topicOption.forEach(option=>{
							if(option.isAnswer == 1){
								item.answer.push(option.textKey);
							}
						})
						item.value = [];
					}else if(item.modelType == 'select' || item.modelType == 'radio' || item.modelType == 'imagesRadio'){
						item.answer = '';
						item.topicOption.forEach(option=>{
							if(option.isAnswer == 1){
								item.answer = option.textKey;
							}
						})
						// item.value = ''
					}else if(item.modelType == 'lc-blanks'){
						item.value = [];
						item.topicOption.forEach(option=>{
							if(option.textVal.indexOf('editcont') > -1){
								const parser = new DOMParser();
								const doc = parser.parseFromString(option.textVal, 'text/html');
								const textNodes = doc.body.innerText.trim().split('\n');console.log(textNodes)
								option.textVal = textNodes[0]
							}
							if(!option.answerItemVal){
								option.answerItemVal ='';
							}
							if(!option.optionScore){
								option.optionScore = null;
							}
						})
						// item.value = ''
					}else if(item.modelType == 'lc-hblanks'){
						item.value = [];
						item.topicOption.forEach(option=>{
							// 字符串解析为dom
							const parser = new DOMParser();
							const doc = parser.parseFromString(option.textHtmlVal, 'text/html');
							const textNodes = $(doc.body).find('input').length;
							let names = [];
							$(doc.body)[0].childNodes.forEach(nodes=>{
								if(nodes.nodeName == '#text'){
									names.push(nodes)
								}
							})
							if(textNodes>0){
								for(var i=0;i<textNodes;i++){
									if(!option.domHtml[i]){
										option.domHtml[i] = {
											width:$(doc.body).find('input')[i].style.cssText,
											name:names[i]
										}
									}
									if(!option.answerItemVal[i]){
										option.answerItemVal[i] ='';
									}
									if(!option.optionScore[i]){
										option.optionScore[i] = null;
									}
									
								}	
							}
						})
						// item.value = ''
					}else if(item.modelType == 'single_text' || item.modelType == 'multiRow_text'){
						item.answer = '';
						if(item.topicOption){
							item.answer = item.topicOption[0].answer ;
						}
						
						// item.value = ''
					}
					group.push(item);
					if(n){
						if(item.modelType != 'text' && item.modelType != 'hr'){
							_this.widgetList.push(item)	;	
						}
					}
				})
				widgetGroup.question = group;
			})
		},
		// 获取可用控件列表
		getWidgets(formTypeId){
			const _this = this;
			get('/formadmin/exammodel/exam_add.jhtml',{formTypeId}).then(res => {
				if(res.code == 200){
					_this.widgetGroupList = res.data.plugInType;
					_this.typeList = res.data.typeList;
					_this.fromType = res.data.formTypeString;
					sessionStorage.setItem('fromType',res.data.formTypeString);
					_this.changeWidgetGroup(_this.widgetGroupList,true)
					_this.getFormStyleDetail();
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 获取表单详情
		getFormDetail(formId){
			const _this = this;
			get('/formadmin/exammodel/exam_edit.jhtml',{formId}).then(res => {
				if(res.code == 200){
					let formObj = JSON.parse(res.data.formObj);
					_this.list = formObj.list;
					_this.globalStyle = res.data.pcStyleString?JSON.parse(res.data.pcStyleString):formObj.globalStyle;
					_this.mGlobalStyle = res.data.mobileStyleString?JSON.parse(res.data.mobileStyleString):formObj.mGlobalStyle;
					if(_this.globalStyle.itemStyle){
						_this.globalItemStyle.itemStyle = _this.globalStyle.itemStyle;
					}
					if(_this.globalStyle.labelStyle){
						_this.globalItemStyle.labelStyle = _this.globalStyle.labelStyle;
					}
					if(_this.mGlobalStyle.mItemStyle){
						_this.globalItemStyle.mItemStyle = _this.mGlobalStyle.mItemStyle;
					}
					if(_this.mGlobalStyle.mLabelStyle){
						_this.globalItemStyle.mLabelStyle = _this.mGlobalStyle.mLabelStyle;
					}
					if(formObj.status && formObj.status == 2 && sessionStorage.getItem('identityType') !="user"){
						_this.stopPublish(_this.formId);
					}
					_this.fromType = formObj.fromType;
					_this.formTypeId = formObj.formTypeId;
					sessionStorage.setItem('fromType',formObj.fromType)
					sessionStorage.setItem('formTypeId',formObj.formTypeId)

					_this.widgetGroupList = res.data.plugInType;
					_this.typeList = res.data.typeList;
					_this.changeWidgetGroup(_this.widgetGroupList,true)
					_this.getFormStyleDetail();
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 获取表单样式详情
		getFormStyleDetail(){
			const _this = this;
			get('/formadmin/exammodel/exam_add_style.jhtml',{}).then(res => {
				if(res.code == 200){
					_this.headerImgTypeList = res.data.typeList;
					_this.headerImgList = res.data.imgList;
					setTimeout(() => {
						_this.$watch('watchObj', function(){
							this.watchFlag = 1;
						}, {deep: true})
					}, 10);
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 获取模板中心表单详情
		getFormTpl(){
			const _this = this;
			let param = {
				formId:_this.formId,
				fromType:_this.fromType,
				// formTypeId:_this.formTypeId
			}
			get('/superadm/formmodel/loadFormModelData.do',param).then(res => {
				if(res.code == 200){
					let formObj = res.data.formData;
					_this.list = formObj.list?formObj.list:[];
					_this.globalStyle = formObj.pcStyle?JSON.parse(formObj.pcStyle):this.globalStyle;
					_this.mGlobalStyle = formObj.mobileStyle?JSON.parse(formObj.mobileStyle):this.mGlobalStyle;
					if(_this.globalStyle.itemStyle){
						_this.globalItemStyle.itemStyle = _this.globalStyle.itemStyle;
					}
					if(_this.globalStyle.labelStyle){
						_this.globalItemStyle.labelStyle = _this.globalStyle.labelStyle;
					}
					if(_this.mGlobalStyle.mItemStyle){
						_this.globalItemStyle.mItemStyle = _this.mGlobalStyle.mItemStyle;
					}
					if(_this.mGlobalStyle.mLabelStyle){
						_this.globalItemStyle.mLabelStyle = _this.mGlobalStyle.mLabelStyle;
					}
					_this.headerImgTypeList = res.data.imgType;
					_this.headerImgList = res.data.imgList;
					_this.formTypeId = formObj.formTypeId;
					sessionStorage.setItem('formTypeId',formObj.formTypeId)
					
					_this.widgetGroupList = res.data.plugInType;
					_this.typeList = res.data.typeList;
					_this.changeWidgetGroup(_this.widgetGroupList,true)
					_this.changeWidgetGroup(_this.typeList,false)
					_this.getFormStyleDetail();
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 模板中心选用获取模板表单数据
		getTplFormEdit(formId){
			const _this = this;
			post('/formadmin/quote_exam.jhtml',{formId}).then(res => {
				if(res.code == 200){
					let formObj = JSON.parse(res.data.formObj);
					console.log(formObj,33)
					_this.list = formObj.list;
					_this.globalStyle = formObj.pcStyle?JSON.parse(formObj.pcStyle):formObj.globalStyle;
					_this.mGlobalStyle = formObj.mobileStyle?JSON.parse(formObj.mobileStyle):formObj.mGlobalStyle;
					if(_this.globalStyle.itemStyle){
						_this.globalItemStyle.itemStyle = _this.globalStyle.itemStyle;
					}
					if(_this.globalStyle.labelStyle){
						_this.globalItemStyle.labelStyle = _this.globalStyle.labelStyle;
					}
					if(_this.mGlobalStyle.mItemStyle){
						_this.globalItemStyle.mItemStyle = _this.mGlobalStyle.mItemStyle;
					}
					if(_this.mGlobalStyle.mLabelStyle){
						_this.globalItemStyle.mLabelStyle = _this.mGlobalStyle.mLabelStyle;
					}
					_this.widgetGroupList = res.data.plugInType;
					_this.typeList = res.data.typeList;
					_this.fromType = res.data.formTypeString;
					sessionStorage.setItem('fromType',res.data.formTypeString)
					_this.formTypeId = formObj.formTypeId;
					sessionStorage.setItem('formTypeId',formObj.formTypeId)
					_this.changeWidgetGroup(_this.widgetGroupList,true)
					_this.getFormStyleDetail();
					
				}else{
					_this.$message.error(res.message);
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 从左侧拖拽出 过程
		dragMove(e) {
			if(e.to._prevClass == "list widget-list") return false;
			this.dragTipsFlag = false;
			this.isDragging = true;
		},// 从左侧拖拽出 结束
		dragEndType(e) {
			this.isDragging = false;
			let item = JSON.parse(
						JSON.stringify({
							...this.typeList[$(e.item).attr('pindex')].question[$(e.item).attr('cindex')],
							uuid: this.getUuid(),
						})
					);
			if (e.to._prevClass == "parent-list") {
				this.list.splice(e.newDraggableIndex, 1, item);
			}
			if (this.list.length == 0) {
				this.dragTipsFlag = true;
			}
		},
		// 从左侧拖拽出 结束
		dragEnd(e) {
			this.isDragging = false;
			let item = {
				uuid:this.getUuid(),
				...e.item._underlying_vm_,
			}
			
			if (e.to._prevClass == "parent-list") {
				this.list.splice(e.newDraggableIndex, 1, item);
			}
			if (this.list.length == 0) {
				this.dragTipsFlag = true;
			}
		},
		// 右侧拖拽 过程
		childSortMove(e) {
			this.isDragging = true;
			if (e.to._prevClass == "list widget-list") return false;
		},
		// 右侧拖拽完成
		childSortEnd(e) {
			this.isDragging = false;
			let item = e.item._underlying_vm_;
			if (e.to._prevClass == "parent-list") {
				this.list.splice(e.newDraggableIndex, 1, item);
			}
		},
		// 控件生成唯一标识
		getUuid() {
			let s = [];
			let hexDigits = "0123456789abcdef";
			for (let i = 0; i < 36; i++) {
				s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
			}
			s[14] = "4";
			s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
			s[8] = s[13] = s[18] = s[23] = "-";
			return s.join("");
		},
		// 复制控件
		copyWidget(index) {
			let item = JSON.parse(
						JSON.stringify({
							...this.list[index],
							uuid: this.getUuid(),
						})
					)
			this.list.splice(index + 1, 0, item);
		},
		// 删除控件
		deleteWidget(index) {
			this.list.splice(index, 1);
		},
		// 编辑控件
		topicUpdate(index){
			var addEditCount = $(".parent-list").find(".field-item[type='topic_add_edit']").length;
			if(addEditCount > 0){
				this.$message.error("有未完成的题目不可操作!");
				return;
			}
			this.list[index].editState = true;
				this.$forceUpdate()
		},	
		confirmAdd(msg,index){
			this.list.splice(index, 1,msg);
			// this.list[index] = msg;debugger
			// this.$forceUpdate()
		},
		closeAdd(msg,index){
			this.list[index].editState = msg;
		},
		// 打开逻辑弹窗
		showLogic(index){
			this.list[index].logicDialog = true;
		},
		// 逻辑保存
		saveLogic(msg,index){
			// this.list[index] = msg;
			this.list.splice(index, 1,msg);
			// this.$forceUpdate()
		},
		closeLogic(msg,index){
			this.list[index].logicDialog = msg;
		},
		// 上传前校验文件大小 不能超过5M
		beforeImgUpload(file){
			const isLt5M = file.size / 1024 / 1024 < 5;
			if (!isLt5M) {
				this.$message.error("大小不能超过 5MB!");
			}
			return isLt5M;
		},
		// 上传页眉成功
		handleHeaderImgSuccess(file){
			if(file.code == 200){
				this.globalStyle.formHeader.imgSrc = file.data.modelimg;
			}else{
				this.$message.error(file.message);
			}
		},
		// 上传logo成功
		handleFormLogoSuccess(file){
			if(file.code == 200){
				this.globalStyle.formLogo.imgSrc = file.data.modelimg;
			}else{
				this.$message.error(file.message);
			}
		},
		// 上传表单背景图成功
		handleFormBgSuccess(file){
			if(file.code == 200){
				this.globalStyle.formBg.imgSrc = file.data.modelimg;
			}else{
				this.$message.error(file.message);
			}
		},
		// 上传手机表单背景图成功
		handlemFormBgSuccess(file){
			if(file.code == 200){
				this.mGlobalStyle.formBg.imgSrc = file.data.modelimg;
			}else{
				this.$message.error(file.message);
			}
		},
		// 上传表单标题背景图成功
		handleFormTitleSuccess(file){
			if(file.code == 200){
				this.globalStyle.formTitle.textStyle.backgroundImage = file.data.modelimg;
			}else{
				this.$message.error(file.message);
			}
		},
		// 上传表单封面背景图成功
		handleCoverImgSuccess(file){
			if(file.code == 200){
				this.mGlobalStyle.formCover.imgSrc = file.data.modelimg;
			}else{
				this.$message.error(file.message);
			}
		},
		// 上传手机表单标题背景图成功
		handlemFormTitleSuccess(file){
			if(file.code == 200){
				this.mGlobalStyle.formTitle.textStyle.backgroundImage = file.data.modelimg;
			}else{
				this.$message.error(file.message);
			}
		},
	
		// 获取页眉行业
		changeHeaderImg(item){
			const _this = this;
			_this.globalStyle.formHeader.isShow = true;
			_this.globalStyle.formHeader.type = '图片';
			_this.globalStyle.formHeader.imgSrc = item.imgUrl;
			_this.mGlobalStyle.formHeader.isShow = true;
			_this.mGlobalStyle.formHeader.type = '图片';
			_this.mGlobalStyle.formHeader.imgSrc = item.imgUrl;
		},
		// 获取页眉数据
		getHeaderImgList(){
			const _this = this,
			imgType = _this.headerImgTypeId;
			get('/formadmin/superAdmTypeImgs.jhtml',{imgType}).then(res => {
				if(res.code == 200){
					_this.headerImgList = res.data.imgList;
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
		// 保存，预览表单
		saveForm(preview,to){
			const _this = this,
			formHeader = _this.globalStyle.formHeader,
			formBg = _this.globalStyle.formBg,
			formTitle = _this.globalStyle.formTitle,
			formDescript = _this.globalStyle.formDescript,
			submitBtnStyle = _this.globalStyle.submitBtnStyle;
			// 校验页眉
			if(formHeader.isShow && formHeader.type == '文字' && formHeader.textCont == ''){
				this.$message.error('请输入页眉文字！');
				this.equipment = 1;
				this.currentWidget = '';
				this.setShow = true;
				return false;
			}else if(formHeader.isShow && formHeader.type == '图片' && formHeader.imgSrc == ''){
				this.$message.error('请选择页眉图片！');
				this.equipment = 1;
				this.currentWidget = '';
				this.setShow = true;
				return false;
			}
			// 校验背景图
			if(formBg.isShow && formBg.type == '图片' && formBg.imgSrc == ''){
				this.$message.error('请上传背景图片！');
				this.equipment = 1;
				this.currentWidget = '';
				this.setShow = true;
				return false;
			}
			// 校验表单标题
			if(formTitle.isShow && formTitle.textCont == ''){
				this.$message.error('请输入表单标题！');
				this.equipment = 1;
				this.currentWidget = '';
				this.setShow = true;
				return false;
			}
			// 校验表单描述
			if(formDescript.isShow && formDescript.textCont == ''){
				this.$message.error('请输入表单描述！');
				this.equipment = 1;
				this.currentWidget = '';
				this.setShow = true;
				return false;
			}
			// 校验表单提交按钮
			if(submitBtnStyle.text == ''){
				this.$message.error('请输入按钮文字！');
				this.equipment = 1;
				this.currentWidget = '';
				this.setShow = true;
				return false;
			}
			if(_this.list.length == 0){
				if(!(to && (to.path =='/exam_questions' || to.path =='/template'))){
					this.equipment = 1;
					_this.$message.error('请拖入表单控件！');
					return false;
				}
			}else if($('.field-item[type=topic_add_edit]').length > 0){
				_this.$message.error('有未完成的题目不可操作！');
				return false;
			}
			_this.isSaving = true;
			// _this.$emit('changeSaveStatue', true);
			let jsonDate = {
				pcStyle: JSON.stringify(_this.globalStyle),
				mobileStyle:JSON.stringify( _this.mGlobalStyle),
				list: _this.list,
				fromType: _this.formType,
				formTypeId: _this.formTypeId,
				type_id: sessionStorage.getItem('type_id')?sessionStorage.getItem('type_id'):'',
				title: _this.globalStyle.formTitle.textCont
			};
			let url = '/formadmin/exammodel/exam_save.jhtml';
			let param = {jsonDate:JSON.stringify(jsonDate)}
			if(_this.identityType == 'admin'){
				jsonDate._id = _this.formId;
				url = '/superadm/formmodel/saveFormModelData.do';
				param = {jsonData:JSON.stringify(jsonDate)}
			}else if(_this.userId){
				url = '/formadmin/exammodel/exam_save.jhtml';
				param = {jsonDate:JSON.stringify(jsonDate)}
			}else{
				if(_this.formId){
					// 编辑提交
					jsonDate._id = _this.formId;
					url = '/formadmin/exammodel/exam_update.jhtml';
				}
				param = {jsonDate:JSON.stringify(jsonDate)}
			}
				post(url,param).then(res => {
					if(res.code == 200){
						_this.isSaving = false;
						if(res.data.formId){
							_this.formId = res.data.formId;
							sessionStorage.setItem('formId',_this.formId)
						}
						if(_this.formId){
							_this.saveStyleForm(preview,to);
						}
					}else{
						_this.$message.error(res.message);
						_this.isSaving = false;
						_this.watchFlag = 1;
					}
				}).catch(err => {
					_this.$message.error(err);
						_this.isSaving = false;
						_this.watchFlag = 1;
				})
			
		},
		// 保存样式，预览表单
		saveStyleForm(preview,to){
			const _this = this,
			formHeader = _this.globalStyle.formHeader,
			formBg = _this.globalStyle.formBg,
			formTitle = _this.globalStyle.formTitle,
			formDescript = _this.globalStyle.formDescript,
			submitBtnStyle = _this.globalStyle.submitBtnStyle;
			// 校验页眉
			if(formHeader.isShow && formHeader.type == '文字' && formHeader.textCont == ''){
				this.$message.error('请输入页眉文字！');
				this.equipment = 1;
				this.currentWidget = '';
				this.setShow = true;
				return false;
			}else if(formHeader.isShow && formHeader.type == '图片' && formHeader.imgSrc == ''){
				this.$message.error('请选择页眉图片！');
				this.equipment = 1;
				this.currentWidget = '';
				this.setShow = true;
				return false;
			}
			// 校验背景图
			if(formBg.isShow && formBg.type == '图片' && formBg.imgSrc == ''){
				this.$message.error('请上传背景图片！');
				this.equipment = 1;
				this.currentWidget = '';
				this.setShow = true;
				return false;
			}
			// 校验表单标题
			if(formTitle.isShow && formTitle.textCont == ''){
				this.$message.error('请输入表单标题！');
				this.equipment = 1;
				this.currentWidget = '';
				this.setShow = true;
				return false;
			}
			// 校验表单描述
			if(formDescript.isShow && formDescript.textCont == ''){
				this.$message.error('请输入表单描述！');
				this.equipment = 1;
				this.currentWidget = '';
				this.setShow = true;
				return false;
			}
			// 校验表单提交按钮
			if(submitBtnStyle.text == ''){
				this.$message.error('请输入按钮文字！');
				this.equipment = 1;
				this.currentWidget = '';
				this.setShow = true;
				return false;
			}
			_this.isSaving = true;
			_this.globalStyle['itemStyle'] = _this.globalItemStyle.itemStyle;
			_this.globalStyle['labelStyle'] = _this.globalItemStyle.labelStyle;
			_this.mGlobalStyle['mItemStyle'] = _this.globalItemStyle.mItemStyle;
			_this.mGlobalStyle['mLabelStyle'] = _this.globalItemStyle.mLabelStyle;
			let jsonData = {
				pcStyle: JSON.stringify(_this.globalStyle),
				mobileStyle:JSON.stringify( _this.mGlobalStyle),
				formId: _this.formId
			};
			let url = '/formadmin/exammodel/saveFrontQuesStyle.jhtml';
			if(_this.identityType == 'admin'){
				let param = {
					pcStyle:JSON.stringify(_this.globalStyle),
					mobileStyle: JSON.stringify(_this.mGlobalStyle),
					formId: _this.formId,
					_id : _this.formId,
				}
				url = '/superadm/formmodel/saveFormModelData.do';
				jsonData = {jsonData:JSON.stringify(param)}
			}
			post(url,jsonData).then(res => {
				if(res.code == 200){
					_this.isSaving = false;
					if(to){
						_this.watchFlag = 0;
						if(_this.useFlag){
							_this.useFlag = false;
						}
						_this.$router.push(to.fullPath);
						if(preview){
							_this.$router.push({path:'/exam_preview',query:{formId: _this.formId}});
						}else if(sessionStorage.getItem('referrer')){
							sessionStorage.setItem('formId','');
						}
					}else{
					_this.$message({
						type: 'success',
						center: true,
						duration: 1000,
						message: "保存成功！",
						onClose(){
							_this.watchFlag = 0;
							if(_this.useFlag){
								_this.useFlag = false;
								_this.$router.push({path:'/exam_questionTypes'});
							}
							if(preview){
								_this.$router.push({path:'/exam_preview',query:{formId: _this.formId}});
							}else if(sessionStorage.getItem('referrer')){
								sessionStorage.setItem('formId','');
								// window.location.href = sessionStorage.getItem('referrer') + '/formCloseBack.html'
							}
						}
					})
					}
				}
			}).catch(err => {
				_this.$message.error(err);
			})
		},
	},
	beforeRouteLeave(to,from,next){
		if(this.watchFlag == 1){
			this.saveForm(false,to)
		}else{
			if(this.useFlag){
				this.saveForm(false,to)
			}else if(!this.formId){
				this.saveForm(false,to)
			}else{
				next();
			}
		}
	
	}
};
</script>
<style lang="less" scoped>
.full-wrap{
	width: 100%;
	height: 100%;
	.question_types {
		display: flex;
		width: 100%;
		height: 100%;
		.tab_wrap {
			display: flex;
			flex-direction: column;
			width: 290px;
			height: 100%;
			border-right: 1px solid #ddd;
			background-color: #f5f5f5;
			position: relative;
			.nav {
				display: flex;
				align-items: center;
				border-bottom: 1px solid #ddd;
				.item {
					flex: 1;
					display: flex;
					align-items: center;
					justify-content: center;
					height: 50px;
					font-size: 15px;
					cursor: pointer;
				}
				.line {
					width: 1px;
					height: 30px;
					background-color: #ddd;
				}
				.active {
					background-color: #fff;
					color: var(--theme-color);
				}
			}
			.tab_list {
				flex: 1;
				overflow: hidden;
				.tab_item {
					height: 100%;
					padding: 20px 30px 10px;
					overflow-y: auto;
				}
				.widget {
					.model_warp{
						padding:20px 0;
						.title {
							margin: 0 0 10px;
							font-weight: bold;
						}
						.list {
							display: flex;
							justify-content: space-between;
							flex-wrap: wrap;
							.item {
								display: flex;
								align-items: center;
								justify-content: center;
								width: 48%;
								height: 36px;
								border: 1px solid #ddd;
								margin: 0 0 10px;
								border-radius: 2px;
								background-color: #fff;
								font-size: 12px;
								cursor: pointer;
								user-select: none;
								&:hover{
									box-shadow: 0 0 10px rgba(0,0,0,.1);
								}
							}
						}
					}
					.model_warp+.model_warp{
						border-top: 1px solid #bfbfbf;
					}
					/deep/ .el-collapse{
						border: 1px solid #EBEEF5;
						background: #fff;
						.el-collapse-item__header{
							padding-left: 10px;
						}
						.el-collapse-item__wrap{
							border-top: 1px solid #EBEEF5;
							padding:10px;
							margin:0 10px;
							.el-collapse-item__content{
								padding-bottom: 0;
							}
							.item{
								line-height: 40px;
								a{
									&:hover{
										color: var(--theme-color);
									}
								}
							}
						}
					}
				}
				.catalogue {
					.outline_list {
						.outline_item {
							padding:0 5px;
							a {
								display: block;
								height: 36px;
								line-height: 36px;
								text-overflow: ellipsis;
								white-space: nowrap;
								overflow: hidden;
								&:hover {
										color: var(--theme-color);
								}
								i{
									display: inline-block;
									width: 20px;
									height: 20px;
									background: url(../../assets/images/form-icons.png) no-repeat;
									vertical-align: middle;
								}
								.ico-radio{background-position: -96px -26px;}
								.ico-select{background-position: -71px -24px;}
								.ico-checked{background-position: -121px -27px;}
								.ico-single_text{background-position: -47px 5px;}
								.ico-multiRow_text{background-position: -72px 4px;}
								.ico-scale{background-position: -196px -26px;}
								.ico-matrix_radio{background-position: 5px -210px;}
								.ico-matrix_checked{background-position: -18px -211px;}
								.ico-matrix_scale{background-position: -40px -211px;}
								.ico-ganged{background-position: -64px -210px;}
								.ico-imagesRadio{background-position: -83px -210px;}
								.ico-imagesChecked{background-position: -104px -210px;}
								.ico-date{background-position: -96px 4px;}
								.ico-lc-location{background-position: -64px -210px;}
								.ico-lc-blanks{background-position: 4px -236px;}
								.ico-lc-hblanks{background-position: -21px -236px;}
								.ico-lc-table02{background-position: -46px -236px;}
								.ico-lc-classify{background-position: -71px -236px;}

							}
							
						}
					}
				}
			}
			.header_image{
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				overflow: hidden;
				z-index: 9;
				.main{
					display: flex;
					flex-direction: column;
					width: 290px;
					height: 100%;
					background-color: #fff;
					.head{
						display: flex;
						align-items: center;
						justify-content: space-between;
						height: 50px;
						padding: 0 15px;
						border-bottom: 1px solid #ddd;
						font-size: 16px;
						.iconfont{
							margin: 10px 0 0;
							color: #999;
							font-size: 18px;
							cursor: pointer;
							&:hover{
								color: var(--theme-color);
							}
						}
					}
					.style_nav{
						display: flex;
						padding: 10px 15px 0;
						.label{
							color: #999;
						}
						.list{
							flex: 1;
							li{
								float: left;
								padding: 2px 7px;
								margin: 0 0 10px 6px;
								font-size: 12px;
								border: 1px solid #ddd;
								border-radius: 3px;
								color: #666;
								cursor: pointer;
								&:hover{
									border-color: var(--theme-color);
									color: var(--theme-color);
								}
							}
							li.active{
								background-color: var(--theme-color);
								border-color: var(--theme-color);
								color: #fff;
							}
						}
					}
					.img_list{
						flex: 1;
						padding: 10px 10px 0;
						overflow: hidden;
						::v-deep .el-scrollbar{
							height: 100%;
							.el-scrollbar__wrap{
								overflow-x: hidden;
								.el-scrollbar__view{
									display: flex;
									flex-wrap: wrap;
									.item{
										display: flex;
										align-items: center;
										justify-content: center;
										width: 124px;
										height: 40px;
										padding: 2px;
										border: 1px solid #ddd;
										border-radius: 3px;
										margin: 0 5px 10px;
										cursor: pointer;
										position: relative;
										overflow: hidden;
										&:hover{
											border-color: var(--theme-color);
										}
										.bat{
											display: none;
											width: 50px;
											height: 30px;
											line-height: 44px;
											text-align: center;
											background-color: var(--theme-color);
											transform: rotate(45deg);
											position: absolute;
											top: -13px;
											right: -23px;
											z-index: 9;
											.el-icon-check{
												color: #fff;
												font-size: 12px;
												font-weight: bold;
												transform: rotate(-33deg);
											}
										}
										.img{
											width: 100%;
											height: 100%;
											object-fit: contain;
										}
									}
									.item.active{
										border-color: var(--theme-color);
										.bat{
											display: block;
										}
									}
								}
							}							
						}
						::v-deep .item.upload{
							display: flex;
							align-items: center;
							justify-content: center;
							height: 40px;
							padding: 2px;
							border: 1px dashed #ddd;
							border-radius: 3px;
							margin: 0 5px 10px;
							cursor: pointer;
							position: relative;
							overflow: hidden;
							.avatar-uploader{
								width: 100%;
								height: 100%;
								.el-upload{
									display: flex;
									align-items: center;
									justify-content: center;
									width: 100%;
									height: 100%;
									font-size: 12px;
									color: #999;
									.avatar-uploader-icon{
										display: block;
										margin: 0 5px 0 0;
										font-size: 18px;
										color: #aaa;
									}
								}
							}
							&:hover{
								border-color: var(--theme-color);
								.el-upload{
									color: var(--theme-color);
									.avatar-uploader-icon{
										color: var(--theme-color);
									}
								}
							}
						}
					}
				}
			}
		}
		.main_area {
			flex: 1;
			overflow: hidden;
			.edit_cont {
				display: flex;
				height: 100%;
				.edit_wrap{
					flex: 1;
					padding: 50px 20px 20px;
					position: relative;
					overflow-x: auto;
					.equipment_switch{
						display: flex;
						width: 90px;
						height: 34px;
						position: absolute;
						right: 20px;
						top: 8px;
						.btn{
							flex: 1;
							display: flex;
							align-items: center;
							justify-content: center;
							border: 1px solid #ddd;
							background-color: #fff;
							font-size: 22px;
							color: #999;
							cursor: pointer;
							&:hover{
								color: var(--theme-color);
							}
						}
						.el-icon-monitor{
							border-right: none;
							border-radius: 3px 0 0 3px;
						}
						.el-icon-mobile{
							border-left: none;
							border-radius: 0 3px 3px 0;
						}
						.btn.active{
							background-color: var(--theme-color);
							border-color: var(--theme-color);
							color: #fff;
						}
					}
					.form_page{
						height: 100%;
					}
					.edit_inner {
						display: flex;
						flex-direction: column;
						width: 1100px;
						height: 100%;
						margin: 0 auto;
						padding:20px;
						background-color: #fff;
						overflow-y: auto;
						overflow-x: hidden;
						.form-head{
							.img{
								background-color: #f5f5f5;
								img{
									width: 100%;
									object-fit: cover;
								}
							}
						}
						::v-deep .edit-head {
							.form-title {
								position: relative;
								.el-textarea__inner {
									padding: 30px;
									font-size: 20px;
									text-align: center;
								}
								.ques_gross{
									position:absolute;
									width:60px;
									height:60px;
									line-height:30px;
									background:#0cf;
									color:#fff;
									text-align:center;
									border-radius:4px;
									right:0;top:0;
									div{
										font-size:20px;
									}
								}
							}
							.form-descript {
								.el-textarea__inner {
									padding: 5px 30px;
									font-size: 14px;
									color: #999;
									background-color: transparent;
								}
							}
							.el-textarea__inner {
								border: 1px dashed transparent;
								word-break: break-all;
								white-space: normal;
								font-family: inherit;
								border-radius: 0;
								cursor: pointer;
								&:hover,
								&:focus {
									border-color: var(--theme-color) !important;
								}
							}
						}
						.parent-wrap {
							flex: 1;
							padding: 20px 30px;
							.parent-list {
								display: block;
								width: 100%;
								height: 100%;
								.parent-item {
									width: 100%;
									position: relative;
									overflow: hidden;
									
									.cover {
										display: none;
										// width: 100%;
										right:59px;
										height: 100%;
										position: absolute;
										top: 0;
										left: 0;
										// border: 1px solid #fff;
										z-index: 99;
										&:after {
											content: "";
											width: 100%;
											height: 100%;
											position: absolute;
											top: 0;
											left: 0;
											cursor: move;
											box-sizing: border-box;
										}
									}
									&:hover {
										.cover {
											display: block;
										}
										.widget_operate {
											display: flex;
										}
										.control_btn{
											display: block;
										}
										.field-zitem{
											background: #fafafa;
											border-color: #e0e0e0;
										}
										overflow: unset;
									}
									.control_btn{
										display: none;
										width: 58px;
										border-left: 1px solid #e0e0e0;
										background: #f5f5f5;
										position: absolute;
										top: 1px;
										right: 1px;
										bottom: 1px;
										z-index: 1;
										ul{
											display: flex;
											height: 100%;
											justify-content: center;
											flex-direction: column;
											li{
												display: block;
												height: 42px;
												line-height: 42px;
												text-align: center;
												cursor: pointer;
												a{
													display: inline-block;
													width: 20px;
													height: 20px;
													background: url(../../assets/images/form-icons.png);
													vertical-align: middle;
													position: relative;
													i{
														position: absolute;
														width: 16px;
														height: 16px;
														line-height: 15px;
														text-align: center;
														background: #f00;
														color: #fff;
														border-radius: 50%;
														font-size: 12px;
														top: -6px;
														right: -8px;
													}
												}
												.edit_ico{background-position: 1px -82px;}
												.copy_ico{background-position: -25px -82px;}
												.dele_ico{background-position: -52px -82px;}
												.logic_ico{background-position: -80px -82px;}
												&:hover{
													background: #0cf;
													.edit_ico{background-position: 1px -107px;}
													.copy_ico{background-position: -25px -107px;}
													.dele_ico{background-position: -52px -107px;}
													.logic_ico{background-position: -80px -107px;}
												}
											}
										}
									}
											
									.drag-site {
										height: 10px;
									}
								}
								.drag-tips {
									display: flex;
									align-items: center;
									justify-content: center;
									height: 100%;
									max-height: 300px;
									min-height: 200px;
									border: 1px dashed var(--theme-color);
									background-color: #f5f7f9;
									.iconfont {
										font-size: 20px;
										font-weight: bold;
										color: var(--theme-color);
									}
									.txt {
										margin: 0 0 0 10px;
										font-size: 16px;
										color: #666;
									}
								}
								.drag-tips.hide {
									display: none;
								}
								.d-chosen,
								.s-ghost {
									flex: 1 !important;
									min-height: 50px;
									position: relative !important;
									font-size: 0 !important;
									&::after {
										content: "放到这里";
										display: flex;
										align-items: center;
										justify-content: center;
										width: 100%;
										height: 100%;
										position: absolute;
										top: 0;
										left: 0;
										border: 1px dashed #f80;
										color: #f80;
										font-size: 15px;
										background-color: #fff4e8;
										box-sizing: border-box;
									}
								}
							}
						}
						.parent-wrap.is_dragging {
							.children-item:not(.s-ghost) {
								&:after {
									content: "";
									display: block;
									width: 100%;
									height: 100%;
									background-color: rgba(0, 0, 0, 0.2);
									position: absolute;
									top: 0;
									left: 0;
									z-index: 1;
								}
							}
						}
						.submit_btn {
							display: flex;
							align-items: center;
							justify-content: center;
							padding: 10px 0 30px;
							.el-button--primary {
								width: 100px;
								height: 40px;
								padding: 0;
							}
						}
					}
				}
				.set_area {
					width: 330px;
					border-left: 1px solid #e5e5e5;
					background-color: #fff;
					position: relative;
					z-index: 999;
					.close_btn {
						display: flex;
						align-items: center;
						justify-content: center;
						width: 24px;
						height: 56px;
						border: 1px solid #e5e5e5;
						border-right: none;
						margin: -28px 0 0;
						border-radius: 5px 0 0 5px;
						position: absolute;
						top: 50%;
						left: -23px;
						background-color: #fff;
						box-shadow: -3px 0 5px rgba(0, 0, 0, 0.1);
						cursor: pointer;
						z-index: 9;
						.iconfont {
							font-size: 14px;
							color: #999;
							&:hover {
								color: var(--theme-color);
							}
						}
					}
					.set_list {
						height: 100%;
						user-select: none;
						.set_item {
							display: flex;
							flex-direction: column;
							width: 330px;
							height: 100%;
							.title {
								display: flex;
								align-items: center;
								height: 58px;
								padding: 0 24px;
								border-bottom: 1px solid #e5e5e5;
								font-size: 16px;
								white-space: nowrap;
							}
							.list {
								flex: 1;
								overflow-y: auto;
								padding: 0 10px;
								.item {
									padding: 0 5px;
									border-bottom: 1px solid #eee;
									.switch_btn {
										display: flex;
										align-items: center;
										justify-content: space-between;
										height: 50px;
										.label {
											display: flex;
											align-items: center;
											.explain {
												position: relative;
												.el-icon-info {
													display: block;
													margin: 0 0 0 5px;
													color: #aaa;
													cursor: pointer;
													font-size: 18px;
												}
												.cont {
													visibility: hidden;
													opacity: 0;
													background-color: #fff;
													box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
													position: fixed;
													margin: -37px 0 0 -410px;
													z-index: 99;
													transition: all 0.3s;
													.angle {
														width: 10px;
														height: 10px;
														transform: rotate(45deg);
														background: #fff;
														box-shadow: 3px -3px 5px rgba(0, 0, 0, 0.1);
														position: absolute;
														right: -6px;
														top: 20px;
													}
													.tips-main {
														display: flex;
														align-items: center;
														width: 390px;
														padding: 20px 18px;
														.imgbox {
															img {
																display: block;
																width: 200px;
															}
														}
														.infobox {
															margin: 0 0 0 10px;
															.tips-head {
																line-height: 21px;
																color: #707070;
																padding-bottom: 10px;
															}
															.tips-txt {
																font-size: 12px;
																line-height: 18px;
																color: #333;
																.p1 {
																	color: #999;
																}
															}
														}
													}
												}
												&:hover {
													.cont {
														visibility: visible;
														opacity: 1;
														margin-left: -395px;
													}
													.el-icon-info {
														color: var(--theme-color);
													}
												}
											}
										}
										.fold_btn {
											display: flex;
											align-items: center;
											font-size: 13px;
											color: #999;
											cursor: pointer;
											.iconfont {
												font-size: 14px;
												margin: 0 0 0 3px;
												transition: all 0.2s;
											}
											&:hover {
												color: var(--theme-color);
											}
										}
										.fold_btn.active {
											.iconfont {
												margin-top: -3px;
												transform: rotate(-180deg);
												transition: all 0.2s;
											}
										}
									}
									.sub_list {
										padding: 0 10px;
										margin: 0 0 10px;
										background-color: #f5f5f5;
										overflow: hidden;
										.sub_item {
											display: flex;
											justify-content: space-between;
											padding: 10px 0;
											border-top: 1px dashed #ddd;
											.sub_label {
												line-height: 28px;
												color: #888;
												font-size: 13px;
											}
											&:first-child {
												border: none;
											}
										}
									}
									.el-input__inner {
										width: 200px;
										height: 28px;
										font-size: 13px;
										padding: 0 5px;
									}
									.el-input__icon {
										line-height: 28px;
									}
									.el-color-picker {
										display: block;
										margin: 0 0 0 5px;
										border-radius: 2px;
										background-color: #fff;
									}
									.el-textarea {
										width: 200px;
										.el-textarea__inner {
											padding: 5px;
											font-family: inherit;
											font-size: 13px;
										}
									}
									.avatar-uploader {
										.el-upload {
											display: flex;
											align-items: center;
											justify-content: center;
											width: 70px;
											height: 70px;
											border: 1px dashed #d9d9d9;
											border-radius: 3px;
											cursor: pointer;
											.avatar{
												width: 100%;
												height: 100%;
												position: relative;
												img{
													width: 100%;
													height: 100%;
													object-fit: contain;
												}
												.el-icon-error{
													font-size: 20px;
													background-color: #fff;
													border-radius: 50%;
													color: #333;
													position: absolute;
													top: -8px;
													right: -8px;
													z-index: 9;
												}
											}
											.avatar-uploader-icon {
												font-size: 28px;
												color: #8c939d;
											}
											&:hover {
												border-color: var(--theme-color);
											}
										}
									}
									.text_style {
										display: flex;
										.iconfont {
											line-height: 28px;
											margin: 0 0 0 5px;
											color: #888;
											cursor: pointer;
										}
										.iconfont.active {
											color: var(--theme-color);
										}
										.el-input__inner {
											width: 104px !important;
										}
									}
									.silder {
										width: 200px;
										padding: 0px 8px 0 0;
										.el-slider__runway {
											margin: 11px 0;
											.el-slider__bar {
												background-color: var(--theme-color);
											}
											.el-slider__button {
												border-color: var(--theme-color);
											}
										}
									}
									.border_style {
										display: flex;
										.el-input__inner {
												width: 60px;
										}
										.el-select {
											.el-input__inner {
												width: 102px;
												margin: 0 0 0 5px;
											}
										}
									}
								}
							}
						}
					}
				}
			}
			.edit_cont.mobile{
				.edit_wrap{
					padding: 0;
					overflow-y: auto;
					.m_edit_inner{
						width: 447px;
						height: 863px;
						padding: 91px 36px 105px;
						margin: 0 auto;
						background: url(../../assets/images/appear-mobile.png);
						overflow: hidden;
						.scroll_inner{
							height: 100%;
							border: 1px solid #ddd;
							overflow-y: auto;
							.mform-head{
								.img{
									img{
										width: 100%;
										object-fit: cover;
									}
								}
							}
							.mform-title{
								text-align: center;
							}
							.mform-widget{
								.mform-list{
									.mform-item{
										.mform-sub{
											position: relative;
											.item.divider{
												border: none!important;
											}
											.cover{
												width: 100%;
												height: 100%;
												position: absolute;
												top: 0;
												left: 0;
											}
										}
									}
								}
								.no_data{
									margin: 150px 0 0;
								}
							}
						}
					}
				}
			}
		}
	}


}
</style>