import { render, staticRenderFns } from "./ybdNumber.vue?vue&type=template&id=959ece48&scoped=true&"
import script from "./ybdNumber.vue?vue&type=script&lang=js&"
export * from "./ybdNumber.vue?vue&type=script&lang=js&"
import style0 from "./ybdNumber.vue?vue&type=style&index=0&id=959ece48&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "959ece48",
  null
  
)

export default component.exports